import React, { useContext } from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { colorBorderBox, subTitle } from '../../../styled/mixins';
import colors from '../../../styled/colors';
import {
  IMAGE_LIMIT,
} from '../constants';
import { AlertsContext } from '../../../contexts/AlertsContext';
import { ERROR_IMAGE_LIMIT } from '../../../utils/constants/alerts';
import {
  getLanguage,
  getTotalCircles,
  hasValidFonts,
  getImageStatus,
} from '../../../utils/functions/imageValidation';

const Container = styled.div`
  margin: 16px;
  padding: 16px 24px;
  width: 750px;
  ${colorBorderBox}
  @media ${({ theme }) => (theme.device.tablet)} {
    width: 100%;
  }
`;

const SubTitle = styled.h3`
  ${subTitle}
`;

const FileInput = styled.input`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: 0;
  cursor: pointer;
`;

const FileInputBox = styled.div`
  width: 100%;
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  cursor: pointer;
  position: relative;
`;

const Label = styled.p``;
const BoldText = styled.span`
  color: ${({ theme }) => (theme.colors.brand5)};
  font-weight: 600;
`;

const Uploader = ({ setImages }) => {
  const { useToasts } = useContext(AlertsContext);
  const { addToast } = useToasts();
  const handleChange = async (event) => {
    if (event.target.files.length > IMAGE_LIMIT) {
      addToast(ERROR_IMAGE_LIMIT, { appearance: 'error', autoDismiss: true });
      return;
    }
    if (event.target.files.length) {
      let files = [].slice.call(event.target.files);
      files = await Promise.all(files.map(async (file) => {
        const img = {};
        const url = URL.createObjectURL(file);
        const text = await file.text();
        img.src = url || '';
        img.name = file.name || 0;
        img.size = file.size || 0;
        img.language = getLanguage(file);
        img.circles = getTotalCircles(text);
        img.hasValidFonts = hasValidFonts(text);
        img.valid = getImageStatus(img);
        return img;
      }));
      setImages(files);
    }
  };

  return (
    <Container color={colors.brand5}>
      <SubTitle>
        Upload images
      </SubTitle>
      <FileInputBox>
        <FileInput
          multiple
          name="files[]"
          type="file"
          accept="image/svg+xml"
          onChange={handleChange}
        />
        <Label>
          Upload until {IMAGE_LIMIT} <BoldText>svg images</BoldText>
        </Label>
      </FileInputBox>
    </Container>
  );
};

Uploader.propTypes = {
  setImages: PropTypes.func.isRequired,
};

export default Uploader;
