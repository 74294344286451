import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { ArrowIcon } from '../assets/icons';

const Container = styled.div`
  width: 100%;
  display: flex;
  margin: 16px 0;
  flex-direction: column;
  padding: 0 8px;
  border-bottom: 2px solid ${({ theme }) => (theme.colors.secondary)};
  border-right: 2px solid ${({ theme }) => (theme.colors.secondary)};
  border-left: 2px solid ${({ theme }) => (theme.colors.secondary)};
  border-radius: 0 0 6px 6px;
`;

const Subtitle = styled.h2`
  font-size: 18px;
  margin: 0 0 14px;
  font-weight: 600;
  color: ${({ theme }) => (theme.colors.white)};
`;

const OpenBox = styled.div`
  display: flex;
  justify-content: space-between;
  align-item: baseline;
  margin-top: 4px;
`;

const Icon = styled.img`
  cursor: pointer;
  transition: 0.3s;
  height: 24px;
  ${({ open }) => (open ? 'transform: rotate(-180deg);' : '')}
`;

const Tab = ({
  title,
  children,
  className,
  handlerOpen,
  open,
}) => (
  <Container className={className}>
    <OpenBox>
      <Subtitle>
        {title}
      </Subtitle>
      <Icon open={open} src={ArrowIcon} onClick={() => handlerOpen()} />
    </OpenBox>
    {children}
  </Container>
);

Tab.propTypes = {
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
  title: PropTypes.string.isRequired,
  handlerOpen: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
};

Tab.defaultProps = {
  className: '',
};

export default Tab;
