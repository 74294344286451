import { Auth as CognitoAuth } from 'aws-amplify';
import * as Sentry from '@sentry/react';
import { AdminProvider } from './entities';

export default class Auth {
  static async signIn(username, password) {
    const result = {
      message: '',
      success: false,
      data: {},
    };
    try {
      const user = await CognitoAuth.signIn(username, password);
      result.data = user;
      result.success = true;
      if (user.challengeName !== 'NEW_PASSWORD_REQUIRED') {
        const session = await CognitoAuth.currentSession();
        const groups = session.accessToken.payload['cognito:groups'];
        window.localStorage.setItem('userGroups', groups);
        window.localStorage.setItem('userEmail', username);
      }
    } catch (error) {
      result.message = error.message;
      Sentry.captureException(error);
    }
    return result;
  }

  static async completeNewPassword(user, username, newPassword, name, lastName) {
    const result = {
      message: '',
      success: false,
      data: {},
    };
    try {
      await CognitoAuth.completeNewPassword(user, newPassword);
      const userAuth = await CognitoAuth.signIn(username, newPassword);
      result.data = userAuth;
      result.success = true;
      await AdminProvider.create(name, lastName, username);
      const session = await CognitoAuth.currentSession();
      const groups = session.accessToken.payload['cognito:groups'];
      window.localStorage.setItem('userEmail', userAuth.attributes.email);
      window.localStorage.setItem('userGroups', groups);
    } catch (error) {
      result.success = error.message;
      Sentry.captureException(error);
    }
    return result;
  }

  static async signOut() {
    try {
      await CognitoAuth.signOut();
      window.localStorage.clear();
    } catch (error) {
      Sentry.captureException(error);
    }
  }

  // In data return the userEmail and the tokenId
  static async isAuthenticated() {
    const result = {
      message: '',
      success: false,
      data: {},
    };
    const userEmail = window.localStorage.getItem('userEmail');
    if (userEmail) {
      result.success = true;
    }
    return result;
  }

  static async getTokenId() {
    try {
      const session = await CognitoAuth.currentSession();
      const { jwtToken } = session.idToken;
      return jwtToken;
    } catch (error) {
      Sentry.captureException(error);
      return '';
    }
  }
}
