import gql from 'graphql-tag';

const createAdminUser = gql`
  mutation createAdminUser($adminUserSettings: AdminUserSettings!) {
    createAdminUser(adminUserSettings: $adminUserSettings) {
      name
      email
    }
  }
`;

export default createAdminUser;
