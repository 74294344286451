export const LANGUAGE_LU = [
  {
    id: 1,
    value: 'en',
    label: 'English',
  },
  {
    id: 2,
    value: 'es',
    label: 'Spanish',
  },
];

export const CONTENT_LU = [
  {
    id: 1,
    value: 1,
    label: 'All',
  },
  {
    id: 2,
    value: 2,
    label: 'Tagging',
  },
  {
    id: 3,
    value: 3,
    label: 'Production',
  },
];

export const LIMIT = 10;
export const ALL_CONTENT = 1;
export const TAGGING_CONTENT = 2;
export const PROD_CONTENT = 3;
export const DEFAULT_LANGUAGE = 'en';
