import React, { useState, useContext, useEffect } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { AlertsContext } from '../../../contexts/AlertsContext';
import {
  Button, Tab, Select,
} from '../../../components';
import { TagsProvider, ImageProvider } from '../../../services/entities';
import { LANGUAGES_SUFIX } from '../../../utils/constants/globals';
import { SUCCESS_TAGS_MESSAGE } from '../../../utils/constants/alerts';

const ContainerInputs = styled.div`
  overflow: scroll;
  padding: 0 8px;
  transition: 0.2s;
  ${({ open }) => (!open ? 'max-height: 0;' : 'max-height: 270px;')}
  @media ${({ theme }) => (theme.device.tablet)} {
    ${({ open }) => (!open ? 'max-height: 0;' : 'max-height: 250px;')}
  }
`;

const CustomButton = styled(Button)`
  && {
    margin: 8px 0;
    background: ${({ theme }) => (theme.colors.gray60)};
    ${({ open }) => (open ? 'display: flex; justify-content: center;' : 'display: none;')}
  }
`;

const LabelInput = styled.div`
  line-height: 1em;
  font-weight: 600;
  margin-bottom: .5em;
  display: flex;
  color: ${({ theme }) => (theme.colors.white)};
`;

const TagBox = styled.div`
  width: 100%;
  margin-bottom: 8px;
  @media ${({ theme }) => (theme.device.mobileL)} {
    width: 270px;
    margin: 0;
  }
`;

const TagCategoryForm = ({
  image,
  setImage,
  setImages,
  images,
  className,
  open,
  handlerOpen,
  luCategories,
}) => {
  const [isLoading, setIsLoading] = useState(false);
  const [newImage, setNewImage] = useState(image);
  const { useToasts } = useContext(AlertsContext);
  const { addToast } = useToasts();
  const categoriesKeys = Object.keys(image.categories);
  const getOptions = async (inputValue) => {
    const result = await TagsProvider.search(inputValue, image.language);
    const { data, success } = result;
    const columnName = `name${LANGUAGES_SUFIX[image.language]}`;
    if (success) {
      return data.map((option) => ({
        id: option.id,
        label: option[columnName],
        value: option.id,
      }));
    }
    return [];
  };
  useEffect(() => {
    setNewImage(image);
  }, [image]);

  const addTags = (option, category) => {
    const payload = option || [];
    const { categories } = newImage;
    const newCategories = {};
    const categoryObject = luCategories.find((cat) => cat.name === category);
    newCategories[category] = payload.map((p) => ({
      ...p,
      categoryId: categoryObject.id,
      category,
    }));
    const newImageTags = {
      ...newImage,
      categories: {
        ...categories,
        ...newCategories,
      },
    };
    setNewImage(newImageTags);
  };

  const handlerOnclick = async () => {
    setIsLoading(true);
    const newRelations = Object.values(newImage.categories);
    const categoryTags = [];
    newRelations.forEach((relation) => {
      if (relation.length) {
        const newRelation = relation.map((r) => ({
          tagId: r.id,
          categoryId: r.categoryId,
        }));
        categoryTags.push(...newRelation);
      }
    });
    const {
      success,
      message,
    } = await ImageProvider.editImage(image.id, null, categoryTags);

    if (success) {
      const newImages = [...images].map((i) => {
        if (i.id === image.id) {
          return {
            ...i,
            ...newImage,
          };
        }
        return i;
      });
      setImage(newImage);
      setImages([...newImages]);
      setIsLoading(false);
      handlerOpen();
      addToast(SUCCESS_TAGS_MESSAGE, { appearance: 'success', autoDismiss: true });
    } else {
      setIsLoading(false);
      addToast(message, { appearance: 'error', autoDismiss: true });
    }
  };

  return (
    <Tab
      title="Tags"
      open={open}
      handlerOpen={handlerOpen}
    >
      <>
        <ContainerInputs
          className={className}
          open={open}
        >
          {categoriesKeys.map((category) => (
            <>
              <LabelInput>
                {category}
              </LabelInput>
              <TagBox>
                <Select
                  value={newImage.categories[category]}
                  onChange={(_, option) => addTags(option, category)}
                  isSearchable
                  isAsync
                  isMulti
                  loadOptions={getOptions}
                  isLine
                  placeholder="Add tags..."
                />
              </TagBox>
            </>
          ))}
        </ContainerInputs>
        <CustomButton
          open={open}
          isLoading={isLoading}
          onClick={handlerOnclick}
        >
          Save
        </CustomButton>
      </>
    </Tab>
  );
};

TagCategoryForm.propTypes = {
  image: PropTypes.shape({
    id: PropTypes.string,
    name: PropTypes.string,
    categories: PropTypes.oneOfType(Object),
    imageUrl: PropTypes.string,
    imageType: PropTypes.string,
    language: PropTypes.string,
    resPhotoAuthor: PropTypes.string,
    resPhotoCredit: PropTypes.string,
    resPhotoRoyaltyFree: PropTypes.bool,
    resPhotoSource: PropTypes.string,
    resVecAuthor: PropTypes.string,
    resVecCredit: PropTypes.string,
    resVecRoyaltyFree: PropTypes.bool,
    resVecSource: PropTypes.string,
    resTypoAuthor: PropTypes.string,
    resTypoCredit: PropTypes.string,
    resTypoRoyaltyFree: PropTypes.bool,
    resTypogSource: PropTypes.string,
  }).isRequired,
  setImages: PropTypes.func.isRequired,
  setImage: PropTypes.func.isRequired,
  images: PropTypes.oneOfType(Array).isRequired,
  className: PropTypes.string,
  open: PropTypes.bool.isRequired,
  handlerOpen: PropTypes.func.isRequired,
  luCategories: PropTypes.oneOfType(Array).isRequired,
};

TagCategoryForm.defaultProps = {
  className: '',
};

export default TagCategoryForm;
