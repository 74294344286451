import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import Input from './Input';

const CustumInput = styled(Input)`
  && {
    font-size: 16px;
    color: ${({ theme }) => (theme.colors.white)};
    div {
      font-weight: 300;
    }
    input {
      border: 0;
      outline: 0;
      background: transparent;
      padding: 0;
      font-size: 14px;
      border-bottom: 2px solid ${({ theme }) => (theme.colors.white)};
      color: ${({ theme }) => theme.colors.white};
      border-radius: 0;
      :focus {
        outline: none !important;
        color: ${({ theme }) => theme.colors.white};
        font-size: 14px;
      }
    }
  }
`;

const InputLine = ({
  className,
  helper,
  id,
  label,
  onChange,
  placeholder,
  required,
  type,
  value,
}) => (
  <CustumInput
    onChange={onChange}
    helper={helper}
    label={label}
    value={value}
    placeholder={placeholder}
    id={id}
    type={type}
    required={required}
    className={className}
  />

);

InputLine.propTypes = {
  helper: PropTypes.string,
  id: PropTypes.string,
  label: PropTypes.string,
  onChange: PropTypes.func,
  placeholder: PropTypes.string,
  required: PropTypes.bool,
  type: PropTypes.string,
  value: PropTypes.string,
  className: PropTypes.string,
};

InputLine.defaultProps = {
  onChange: () => {},
  helper: '',
  placeholder: ' ',
  label: '',
  value: '',
  id: '',
  type: 'text',
  required: false,
  className: '',
};

export default InputLine;
