import React, { useState, useContext } from 'react';
import styled from 'styled-components';
import { useHistory } from 'react-router-dom';
import { Button, Input, PiperAdvice } from '../../../components';
import Auth from '../../../services/Auth.provider';
import { AlertsContext } from '../../../contexts/AlertsContext';
import { ERROR_CREATE_USER_MESSAGE } from '../../../utils/constants/alerts';

const Container = styled.div`
  display: flex;
  width: 100%;
  justify-content: center;
  @media ${({ theme }) => (theme.device.mobileL)} {
    padding: 38px 24px;
  }
`;
const FormContainer = styled.div`
  max-width: 392px;
  width: 100%;
`;

const Form = () => {
  const [user, setUserInfo] = useState({
    username: '',
    password: '',
    newPassword: '',
    name: '',
    lastName: '',
  });
  const [newPasswordRequired, setNewPassworRequires] = useState(false);
  const [cognitoUser, setCognitoUser] = useState(null);
  const history = useHistory();
  const { useToasts } = useContext(AlertsContext);
  const { addToast } = useToasts();
  const handleInputChange = (e) => {
    const { id, value } = e.target;
    setUserInfo({
      ...user,
      [id]: value,
    });
  };
  const logIn = async () => {
    const { username, password } = user;
    if (!username || !password) return;
    const { data, success, message } = await Auth.signIn(username, password);
    if (success) {
      setCognitoUser(data);
      const changePasswordReq = data.challengeName === 'NEW_PASSWORD_REQUIRED';
      setNewPassworRequires(changePasswordReq);
      if (!changePasswordReq) {
        history.push({ pathname: '/tagging' });
      }
    } else {
      addToast(message, { appearance: 'error', autoDismiss: true });
    }
  };
  const authLogIn = async () => {
    const {
      newPassword,
      username,
      name,
      lastName,
    } = user;
    if (newPassword && username && name && lastName) {
      await Auth.completeNewPassword(
        cognitoUser,
        username,
        newPassword,
        name,
        lastName,
      );
      history.push({ pathname: '/tagging' });
    } else {
      addToast(ERROR_CREATE_USER_MESSAGE, { appearance: 'error', autoDismiss: true });
    }
  };

  return (
    <Container>
      <FormContainer>
        <Input
          id="username"
          onChange={handleInputChange}
          label="E-mail"
          value={user.username}
        />
        <Input
          id="password"
          onChange={handleInputChange}
          label="Password"
          type="password"
          value={user.password}
        />
        {newPasswordRequired && (
        <>
          <PiperAdvice>
            Please add a new password and your name to continue and press the button
          </PiperAdvice>
          <Input
            id="name"
            onChange={handleInputChange}
            label="First name"
            type="text"
            value={user.name}
            required
          />
          <Input
            id="lastName"
            onChange={handleInputChange}
            label="Last name"
            type="text"
            value={user.lastName}
            required
          />
          <Input
            id="newPassword"
            onChange={handleInputChange}
            label="New password"
            type="password"
            value={user.newPassword}
            required
            helper="Require numbers,  special character, uppercase letters, lowercase letters, minimum length 8"
          />
        </>
        )}
        <Button onClick={newPasswordRequired ? authLogIn : logIn}>
          Log in
        </Button>
      </FormContainer>
    </Container>
  );
};

export default Form;
