export const SUCCESS_DELETE_MESSAGE = 'Deleted successfully';
export const SUCCESS_ADD_TAG_MESSAGE = 'Tag added successfully';
export const SUCCESS_TAGS_MESSAGE = 'Tags Saved successfully';
export const ERROR_CREATE_USER_MESSAGE = 'Please fill all the fields';
export const SUCCES_ADDED_TO_STAGE = 'Images added successfully';
export const ERROR_CREATE_TAG = 'Tag already exist';
export const ERROR_IMAGE_LIMIT = 'Image limit exceeded';
export const ERROR_UPLOAD_IMAGES = 'Error uploading images';
export const SUCCES_UPLOAD_IMAGES = 'Images uploaded successfully';
export const ERROR_DELETE_MESSAGE = 'Error deleting image';
export const SUCCESS_UPDATE_IMAGE = 'Image updated successfully';
export const ERROR_UPDATE_IMAGE = 'Error to update image';
