/* eslint-disable react/forbid-prop-types */
/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import PropTypes from 'prop-types';
import ReactSelect from 'react-select';
import Creatable from 'react-select/creatable';
import Async from 'react-select/async';
import styled from 'styled-components';

const StyledReactSelect = styled(ReactSelect)`
  &&{
    min-height: 40px;
    padding-top: 4px;
    padding-bottom: 4px;
    max-width: 100%;
    [class*="-control"]{
      border-color: ${({ theme }) => theme.colors.gray90};
      ${({ isLine, theme }) => (isLine
    ? `background: ${theme.colors.gray60}; border: 2px solid ${theme.colors.white}; input { color: white !important; }`
    : `border-color: ${theme.colors.gray90}`
  )}
    }
    [class*="-singleValue"]{
      width: 100%;
    }
    [class*="-indicatorSeparator"]{
      display: none;
    }
    [class*="-menu"]{
      z-index: 2;
    }
    [class*="-multiValue"]{
      background-color: ${({ theme }) => theme.colors.secondary};
      margin: 0 2px;
    }
    ${({ isLine, theme }) => (isLine ? (
    `[class*="-Input"]{
        color: ${theme.colors.white} !important;
      }
      [class*="-indicatorContainer"]{
        color: ${theme.colors.white} !important;
      }
    `
  ) : '')}
    
    ${(props) => (props.isSearchable) && `
      [class*="-indicatorContainer"]{
        &:last-child{
          display: none;
        }
      }
    `}
  }
`;

const Select = ({
  id,
  onChange,
  isSearchable,
  isCreatable,
  isAsync,
  suffix,
  isLine,
  ...props
}) => {
  const handleChange = (selectedOption) => {
    onChange(id, selectedOption);
  };

  const renderImage = (image) => ({
    alignItems: 'center',
    display: 'flex',
    ':before': {
      flex: '0 0 24px',
      borderRadius: image.isRounded ? '100%' : 0,
      content: '""',
      display: 'block',
      margin: `${image.hasPositionLeft ? '0 8px 0 0' : '0 0 0 8px'}`,
      height: 24,
      background: `url(${image.src})`,
      backgroundRepeat: 'no-repeat',
      backgroundSize: 'cover',
      backgroundPosition: 'center',
      order: image.hasPositionLeft ? 0 : 2,
    },
  });

  const renderSuffix = () => ({
    ':after': {
      content: `" ${suffix}"`,
    },
  });

  const renderCustomStyles = (data) => {
    if (data.image) {
      return renderImage(data.image);
    }
    if (suffix) {
      return renderSuffix();
    }
    return null;
  };

  const customStyles = {
    option: (styles, { data }) => ({ ...styles, ...renderCustomStyles(data) }),
    singleValue: (styles, { data }) => ({ ...styles, ...renderCustomStyles(data) }),
  };

  return isAsync ? (
    <StyledReactSelect
      as={Async}
      onChange={handleChange}
      isSearchable={isSearchable}
      styles={customStyles}
      isLine={isLine}
      {...props}
    />
  ) : (
    <StyledReactSelect
      as={isCreatable ? Creatable : ReactSelect}
      onChange={handleChange}
      isSearchable={isSearchable}
      styles={customStyles}
      isLine={isLine}
      {...props}
    />
  );
};

Select.propTypes = {
  id: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  options: PropTypes.instanceOf(Array),
  value: PropTypes.any.isRequired,
  isSearchable: PropTypes.bool,
  isCreatable: PropTypes.bool,
  isAsync: PropTypes.bool,
  suffix: PropTypes.string,
  isLine: PropTypes.bool,
};

Select.defaultProps = {
  options: [],
  isSearchable: false,
  isCreatable: false,
  isAsync: false,
  suffix: null,
  isLine: false,
};

export default Select;
