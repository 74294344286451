import {
  BYTES_LIMITS,
  IMPORTS_FONTS,
  FONT_FAMILIES,
} from '../constants/globals';

export const getLanguage = (file) => {
  if (file.name.includes('-eng')) {
    return 'en';
  }
  if (file.name.includes('-esp')) {
    return 'es';
  }
  return null;
};

export const getTotalCircles = (text = '') => {
  const arr = [...text.matchAll('<circle', 'g')] || [];
  return arr.length;
};

export const hasValidFonts = (text) => {
  // check imports
  // eslint-disable-next-line no-useless-escape
  const regexp = RegExp('\@import url.*\;', 'g');
  const imports = [...text.matchAll(regexp)] || [];
  const invalidImports = [];
  const invalidFontFamilies = [];
  if (imports.length) {
    imports.forEach((imp) => {
      const [impObject] = imp;
      if (IMPORTS_FONTS.indexOf(impObject) < 0) {
        invalidImports.push(impObject);
      }
    });
  }
  // check font types
  // eslint-disable-next-line no-useless-escape
  const regexpTag = RegExp('<text.*\>', 'g');
  const textsTags = [...text.matchAll(regexpTag)] || [];
  if (textsTags.length) {
    textsTags.forEach((tag) => {
      const [tagObject] = tag;
      // eslint-disable-next-line no-useless-escape
      const regexpFontFamily = RegExp("(?<=font\-family\:( ?)\').*(?=\'\;)", 'g');
      const [fontFamily] = [...tagObject.matchAll(regexpFontFamily)] || [];
      if (fontFamily.length && (FONT_FAMILIES.indexOf(fontFamily[0]) < 0)) {
        invalidFontFamilies.push(fontFamily[0]);
      }
    });
  }
  return !invalidImports.length && !invalidFontFamilies.length;
};

export const getImageStatus = (img) => ((img.size < BYTES_LIMITS)
    && img.hasValidFonts
    && img.language
    && !img.circles
);
