import React, { useState, useEffect, useContext } from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { colorBorderBox, subTitle } from '../../../styled/mixins';
import { Input, Button } from '../../../components';
import { TagsProvider } from '../../../services/entities';
import { AlertsContext } from '../../../contexts/AlertsContext';
import { SUCCESS_ADD_TAG_MESSAGE, ERROR_CREATE_TAG } from '../../../utils/constants/alerts';
import capitalize from '../../../utils/functions/capitalize';

const SubTitle = styled.h3`
  ${subTitle}
`;

const AddTagBox = styled.div`
  ${colorBorderBox}
`;

const InputBox = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
`;

const CustomInput = styled(Input)`
&&{
  width: 300px;
  @media ${({ theme }) => (theme.device.mobileL)} {
    width: 100%;
  }
}
`;

const CustomButton = styled(Button)`
&&{
  width: 300px;
  @media ${({ theme }) => (theme.device.mobileL)} {
    width: 100%;
  }
}
`;

const AddTags = ({ setTags, tags }) => {
  const [tag, setTag] = useState({
    nameEn: '',
    nameEs: '',
  });
  const [disabled, setDisabled] = useState(true);
  const [isLoading, setIsLoading] = useState(false);
  const { useToasts } = useContext(AlertsContext);
  const { addToast } = useToasts();

  useEffect(() => {
    const { nameEn, nameEs } = tag;
    if (nameEn && nameEs) {
      setDisabled(false);
    } else {
      setDisabled(true);
    }
  }, [tag]);

  const onClick = async () => {
    setIsLoading(true);
    const { nameEn, nameEs } = tag;
    const { success, data } = await TagsProvider
      .create(capitalize(nameEn.trim()), capitalize(nameEs.trim()));
    if (success) {
      const newTags = [data, ...tags];
      setTag({
        nameEn: ' ',
        nameEs: ' ',
      });
      setTags(newTags);
      addToast(SUCCESS_ADD_TAG_MESSAGE, { appearance: 'success', autoDismiss: true });
    } else {
      addToast(ERROR_CREATE_TAG, { appearance: 'error', autoDismiss: true });
    }
    setIsLoading(false);
  };
  const onChange = (e) => {
    const { id, value } = e.target;
    setTag({
      ...tag,
      [id]: capitalize(value),
    });
  };
  return (
    <AddTagBox>
      <SubTitle>
        Add tags
      </SubTitle>
      <InputBox>
        <CustomInput
          label="English tag name:"
          id="nameEn"
          required
          onChange={onChange}
          value={tag.nameEn}
        />
        <CustomInput
          label="Spanish tag name:"
          id="nameEs"
          required
          onChange={onChange}
          value={tag.nameEs}
        />
      </InputBox>
      <CustomButton
        disabled={disabled}
        onClick={() => onClick()}
        isLoading={isLoading}
      >
        Add tag
      </CustomButton>
    </AddTagBox>
  );
};

AddTags.propTypes = {
  setTags: PropTypes.func.isRequired,
  tags: PropTypes.instanceOf(Array).isRequired,
};

export default AddTags;
