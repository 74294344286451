import React, { createContext } from 'react';
import PropTypes from 'prop-types';
import { ToastProvider, useToasts } from 'react-toast-notifications';

export const AlertsContext = createContext(null);

const AlertsProvider = ({
  children,
}) => (
  <AlertsContext.Provider
    value={{
      useToasts,
    }}
  >
    <ToastProvider>
      {children}
    </ToastProvider>
  </AlertsContext.Provider>
);

AlertsProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

export default AlertsProvider;
