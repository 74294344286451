import React, { createContext, useState } from 'react';
import PropTypes from 'prop-types';
import { DrawerMenu } from '../components';
import useComponentVisible from '../hooks/useComponentVisible';
import ImageSettings from '../views/Tagging/components/ImageSettings';

export const ImageEditorContext = createContext(null);

const ImageEditorProvider = ({ children }) => {
  const { ref, isComponentVisible, setIsComponentVisible } = useComponentVisible(false);
  const [image, setImage] = useState(null);
  const [images, setImages] = useState([]);
  const [luCategories, setLuCategories] = useState([]);
  return (
    <ImageEditorContext.Provider
      value={{
        isComponentVisible,
        setIsComponentVisible,
        image,
        setImage,
        images,
        setImages,
        luCategories,
        setLuCategories,
      }}
    >
      <DrawerMenu
        drawerMenuRef={ref}
        isComponentVisible={isComponentVisible}
        setIsComponentVisible={setIsComponentVisible}
        title="Image Settings"
      >
        {image && (
          <ImageSettings
            image={image}
            setImages={setImages}
            setImage={setImage}
            images={images}
            luCategories={luCategories}
            setIsComponentVisible={setIsComponentVisible}
          />
        )}
      </DrawerMenu>
      {children}
    </ImageEditorContext.Provider>
  );
};

ImageEditorProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

export default ImageEditorProvider;
