import * as Sentry from '@sentry/react';
import { TagsMock } from '../../mocks/entities';
import { ENVIRONMENTS, ENV, LANGUAGES_SUFIX } from '../../utils/constants/globals';
import appsyncClient from '../appsync/AppsyncClient';
import { createTag as createTagMutation, deleteTag } from '../../graphql/mutations';
import { tags as tagsQuery } from '../../graphql/queries';

export default class TagsProvider {
  static async create(name, nameEs) {
    const result = {
      message: '',
      success: false,
      data: {},
    };
    try {
      let payload;
      if (ENV !== ENVIRONMENTS.mock) {
        payload = await appsyncClient.mutate({
          mutation: createTagMutation,
          fetchPolicy: 'no-cache',
          variables: {
            name,
            nameEs,
          },
        });
        payload = payload.data.createTag;
      } else {
        payload = { id: '', name, nameEs };
      }
      result.message = 'success';
      result.success = true;
      result.data = payload;
    } catch (err) {
      result.message = err.message;
      result.success = false;
      Sentry.captureException(err);
    }
    return result;
  }

  static async search(searchValue = '', language = 'en', categoryId = 0) {
    const result = {
      message: '',
      success: false,
      data: [],
    };

    const columnName = `name${LANGUAGES_SUFIX[language]}`;
    try {
      let payload;
      const variables = {
        sort: [
          ['id', 'DESC'],
        ],
        where: `{ "${columnName}": { "$like": "%${searchValue}%" } }`,
      };
      if (categoryId) {
        variables.categoryId = categoryId;
      }
      if (ENV !== ENVIRONMENTS.mock) {
        payload = await appsyncClient.query({
          query: tagsQuery,
          fetchPolicy: 'network-only',
          variables,
        });
      } else {
        payload = TagsMock;
      }
      result.message = 'success';
      result.success = true;
      result.data = payload.data.tags;
    } catch (err) {
      result.message = err.message;
      Sentry.captureException(err);
    }
    return result;
  }

  static async delete(id) {
    const result = {
      message: '',
      success: false,
      data: {},
    };
    try {
      let payload;
      if (ENV !== ENVIRONMENTS.mock) {
        payload = await appsyncClient.mutate({
          mutation: deleteTag,
          fetchPolicy: 'no-cache',
          variables: {
            id,
          },
        });
        payload = payload.data.deleteTag;
      } else {
        payload = true;
      }
      result.message = 'success';
      result.success = true;
      result.data = payload;
    } catch (err) {
      result.message = err.message;
      result.success = false;
      Sentry.captureException(err);
    }
    return result;
  }
}
