import React, { useContext, useState } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { AlertsContext } from '../../../contexts/AlertsContext';
import { centerFlex } from '../../../styled/mixins';
import { Button } from '../../../components';
import { ImageProvider } from '../../../services/entities';
import {
  SUCCESS_DELETE_MESSAGE,
  ERROR_DELETE_MESSAGE,
  ERROR_UPLOAD_IMAGES,
  SUCCES_UPLOAD_IMAGES,
} from '../../../utils/constants/alerts';
import {
  getLanguage,
  getTotalCircles,
  hasValidFonts,
  getImageStatus,
} from '../../../utils/functions/imageValidation';

const ImageBox = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
`;
const Image = styled.img`
  width: 120px;
  height: 120px;
`;
const ButtonBox = styled.div`
  ${centerFlex};
  flex-direction: column;
  margin-left: 16px;
`;

const CustomButton = styled(Button)`
  && {
    margin: 8px 0;
    background: ${({ theme }) => (theme.colors.secondary)};
    color: ${({ theme }) => (theme.colors.gray60)};
    border: 2px solid ${({ theme }) => (theme.colors.gray60)};
  }
`;

const CustomReplaceButton = styled(Button)`
  && {
    ${({ isLoading }) => {
    if (!isLoading) {
      return 'padding: 0;';
    }
    return 'padding: 12px 26px;';
  }}
    background: ${({ theme }) => (theme.colors.secondary)};
    color: ${({ theme }) => (theme.colors.gray60)};
    border: 2px solid ${({ theme }) => (theme.colors.gray60)};
    margin: 8px 0;
  }
`;

const FileInputBox = styled.div`
  width: 100%;
  display: flex;
  height: 40px; 
  flex-flow: row nowrap;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  position: relative;
`;

const FileInput = styled.input`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 40px;
  opacity: 0;
  cursor: pointer;
`;

const ImageAction = ({
  image, setImage, setImages, images, setIsComponentVisible,
}) => {
  const { useToasts } = useContext(AlertsContext);
  const [isLoadingDeleteBtn, setIsLoadingDeleteBtn] = useState(false);
  const [isLoadingReplaceBtn, setIsLoadingReplaceBtn] = useState(false);
  const { addToast } = useToasts();
  const replaceImage = async (event, imageId) => {
    if (event.target.files.length) {
      setIsLoadingReplaceBtn(true);
      const files = [].slice.call(event.target.files);
      const file = files[0];
      const img = {};
      const url = URL.createObjectURL(file);
      const text = await file.text();
      img.id = imageId;
      img.src = url || '';
      img.name = file.name || 0;
      img.size = file.size || 0;
      img.language = getLanguage(file);
      img.circles = getTotalCircles(text);
      img.hasValidFonts = hasValidFonts(text);
      img.valid = getImageStatus(img);
      if (img.valid) {
        const { success, data } = await ImageProvider.updateTemplate(img);
        if (success) {
          const newImages = [...images].map((i) => {
            if (i.id === imageId) {
              return {
                ...i,
                imageUrl: data.imageUrl,
              };
            }
            return i;
          });
          setImages([...newImages]);
          setImage({
            ...image,
            imageUrl: data.imageUrl,
          });
          addToast(SUCCES_UPLOAD_IMAGES, { appearance: 'success', autoDismiss: true });
        } else {
          addToast(ERROR_UPLOAD_IMAGES, { appearance: 'error', autoDismiss: true });
        }
      } else {
        addToast(ERROR_UPLOAD_IMAGES, { appearance: 'error', autoDismiss: true });
      }
      setIsLoadingReplaceBtn(false);
    }
  };
  const deleteImage = async (imageId) => {
    setIsLoadingDeleteBtn(true);
    const result = await ImageProvider.deleteImage(imageId);
    if (result.success) {
      const newImages = [...images].filter((img) => img.id !== imageId);
      setIsComponentVisible(false);
      setImages([...newImages]);
      addToast(SUCCESS_DELETE_MESSAGE, { appearance: 'success', autoDismiss: true });
    } else {
      addToast(ERROR_DELETE_MESSAGE, { appearance: 'error', autoDismiss: true });
    }
    setIsLoadingDeleteBtn(false);
  };
  return (
    <ImageBox>
      <Image
        src={image.imageUrl}
      />
      <ButtonBox>
        <CustomReplaceButton
          isLoading={isLoadingReplaceBtn}
        >
          <FileInputBox>
            <FileInput
              name="file"
              type="file"
              accept="image/svg+xml"
              onChange={(e) => replaceImage(e, image.id)}
            />
            Replace Image
          </FileInputBox>
        </CustomReplaceButton>
        <CustomButton
          onClick={() => deleteImage(image.id)}
          isLoading={isLoadingDeleteBtn}
        >
          Delete Image
        </CustomButton>
      </ButtonBox>
    </ImageBox>
  );
};

ImageAction.propTypes = {
  image: PropTypes.shape({
    id: '',
    name: '',
    tags: PropTypes.oneOfType(Array),
    imageUrl: '',
    imageType: '',
  }).isRequired,
  setImages: PropTypes.func.isRequired,
  setImage: PropTypes.func.isRequired,
  images: PropTypes.oneOfType(Array).isRequired,
  setIsComponentVisible: PropTypes.func.isRequired,
};

export default ImageAction;
