import _colors from './colors';
import device from './breakpoints';

export const colors = _colors;

const theme = {
  colors,
  space: 8,
  font: {
    font1: "'Open Sans', 'Helvetica', 'Arial', 'sans-serif'",
    font2: "'QuickSand', 'Open Sans', 'Helvetica', 'Arial', 'sans-serif'",
  },
  secondary: colors.primary,
  primary: colors.white,
  device,
};

export default theme;
