import React, { useContext } from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { colorBorderBox, subTitle } from '../../../styled/mixins';
import { TagsProvider } from '../../../services/entities';
import { TrashIcon } from '../../../assets/icons';
import { Select, PiperAdvice } from '../../../components';
import { LANGUAGE_LU } from '../constant';
import { LANGUAGES_SUFIX } from '../../../utils/constants/globals';
import { AlertsContext } from '../../../contexts/AlertsContext';
import { SUCCESS_DELETE_MESSAGE } from '../../../utils/constants/alerts';

const AllTagsBox = styled.div`
  ${colorBorderBox}
  margin-top: 24px;
`;
const SubTitle = styled.h3`
  ${subTitle}
`;

const TagBox = styled.div`
  border: 1px solid ${({ theme }) => theme.colors.gray02};
  display: flex;
  padding: 8px 16px;
  justify-content: space-between;
`;

const TagLabel = styled.div`
  width: ${({ width }) => (width ? `${width}%` : '40%')};
  text-align : ${({ centered }) => (centered ? 'center' : 'start')};
`;

const TagContainer = styled.div`
  width: 100%;
  height: 500px;
  overflow: scroll;
`;

const Image = styled.img`
  width: 20px;
  height: 20px;
  cursor: pointer;
`;

const SearchBox = styled.div`
  margin: 0 0 8px 0;
  width: 300px;
  @media ${({ theme }) => (theme.device.laptop)} {
    width: 250px;
  }
  @media ${({ theme }) => (theme.device.mobileL)} {
    width: 100%;
  }
`;
const SearchContainer = styled.div`
    width: 100%;
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
`;

const AllTags = ({
  tags,
  setTags,
  setLanguage,
  language,
  setTag,
  tag,
  categories,
  categoryId,
  setCategoryId,
}) => {
  const { useToasts } = useContext(AlertsContext);
  const { addToast } = useToasts();
  const deleteTag = async (id) => {
    const { success, message } = await TagsProvider.delete(id);
    if (success) {
      const newTags = [...tags];
      setTags(newTags.filter((t) => t.id !== id));
      addToast(SUCCESS_DELETE_MESSAGE, { appearance: 'success', autoDismiss: true });
    } else {
      addToast(message, { appearance: 'error', autoDismiss: true });
    }
  };

  const handleSearcherChange = (_, option) => {
    if (option) {
      setTag(option);
    } else {
      setTag({});
    }
  };
  const handleLanguage = (_, option) => {
    if (option) {
      setLanguage(option.value);
    } else {
      setLanguage('en');
    }
    setTag({});
  };

  const handleCategories = (_, option) => {
    if (option) {
      setCategoryId(option.value);
    }
  };

  const getOptions = async (inputValue) => {
    const result = await TagsProvider.search(inputValue, language);
    const { data, success } = result;
    const columnName = `name${LANGUAGES_SUFIX[language]}`;
    if (success) {
      return data.map((option) => ({
        id: option.id,
        label: option[columnName],
        value: option[columnName],
      }));
    }
    return [];
  };

  return (
    <AllTagsBox>
      <SubTitle>
        All tags
      </SubTitle>
      <PiperAdvice>
        Select the language to search a tag
      </PiperAdvice>
      <SearchContainer>
        <SearchBox>
          <Select
            onChange={handleLanguage}
            options={LANGUAGE_LU}
            id="languageId"
            value={LANGUAGE_LU.find((o) => o.value === language)}
          />
        </SearchBox>
        <SearchBox>
          <Select
            onChange={handleCategories}
            options={categories}
            id="categoryId"
            value={categories.find((o) => o.value === categoryId)}
          />
        </SearchBox>
        <SearchBox>
          <Select
            value={tag}
            onChange={handleSearcherChange}
            loadOptions={getOptions}
            id="tagId"
            isAsync
            isSearchable
            isClearable
            placeholder="Search a tag..."
          />
        </SearchBox>
      </SearchContainer>
      <TagBox>
        <TagLabel>
          <b>English name</b>
        </TagLabel>
        <TagLabel>
          <b>Spanish name</b>
        </TagLabel>
        <TagLabel width={10} centered>
          <b>Delete</b>
        </TagLabel>
      </TagBox>
      <TagContainer>
        {tags.map((t) => (
          <TagBox key={t.id}>
            <TagLabel>
              {t.name}
            </TagLabel>
            <TagLabel>
              {t.nameEs}
            </TagLabel>
            <TagLabel width={10} centered>
              <Image src={TrashIcon} onClick={() => deleteTag(t.id)} />
            </TagLabel>
          </TagBox>
        ))}
      </TagContainer>
    </AllTagsBox>
  );
};

AllTags.propTypes = {
  setTags: PropTypes.func.isRequired,
  tags: PropTypes.instanceOf(Array).isRequired,
  setLanguage: PropTypes.func.isRequired,
  language: PropTypes.string.isRequired,
  setTag: PropTypes.func.isRequired,
  tag: PropTypes.instanceOf(Object).isRequired,
  setCategoryId: PropTypes.func.isRequired,
  categories: PropTypes.instanceOf(Array).isRequired,
  categoryId: PropTypes.instanceOf(Object).isRequired,
};

export default AllTags;
