import React, { useState } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import ImageAction from './ImageAction';
import AuthorForm from './AuthorForm';
import TagCategoryForm from './TagCategoryForm';

const Container = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  padding: 16px 0;
`;

const ImageSettings = ({
  image, setImage, setImages, images, setIsComponentVisible, luCategories,
}) => {
  const [openTagCategory, setOpenTagCategory] = useState(false);
  const [openAuthor, setOpenAuthor] = useState(false);
  const handlerOpenAuthor = () => {
    setOpenAuthor(!openAuthor);
    if (!openAuthor) {
      setOpenTagCategory(false);
    }
  };
  const handlerOpenTagCategory = () => {
    setOpenTagCategory(!openTagCategory);
    if (!openTagCategory) {
      setOpenAuthor(false);
    }
  };
  return (
    <Container>
      <ImageAction
        image={image}
        setImage={setImage}
        setImages={setImages}
        images={images}
        setIsComponentVisible={setIsComponentVisible}
      />
      <AuthorForm
        image={image}
        setImage={setImage}
        setImages={setImages}
        images={images}
        open={openAuthor}
        handlerOpen={handlerOpenAuthor}
      />
      <TagCategoryForm
        image={image}
        setImage={setImage}
        setImages={setImages}
        images={images}
        open={openTagCategory}
        luCategories={luCategories}
        handlerOpen={handlerOpenTagCategory}
      />
    </Container>
  );
};

ImageSettings.propTypes = {
  image: PropTypes.shape({
    id: '',
    name: '',
    tags: PropTypes.oneOfType(Array),
    imageUrl: '',
    imageType: '',
  }).isRequired,
  setImages: PropTypes.func.isRequired,
  setImage: PropTypes.func.isRequired,
  images: PropTypes.oneOfType(Array).isRequired,
  luCategories: PropTypes.oneOfType(Array).isRequired,
  setIsComponentVisible: PropTypes.func.isRequired,
};

export default ImageSettings;
