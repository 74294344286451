import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { ALL_CONTENT } from '../constant';
import { ImageEditorContext } from '../../../contexts/ImageEditorContext';
import { Chip } from '../../../components';

const Container = styled.div`
  transition: 0.3s;
  cursor: pointer;
  padding: 0 8px 16px 8px;
  border-radius: 4px;
  &:hover {
    background-color: rgba(0, 0, 0, 0.1);
  }
`;

const RadioBox = styled.div`
display: flex;
align-items: center;
flex-wrap: wrap;
@media ${({ theme }) => (theme.device.mobileL)} {
  display: block;
}
`;

const Check = styled.input`
    width: 16px;
    height: 16px;
    margin: 0;
`;

const Label = styled.p`
margin: 12px 8px 16px 0;
@media ${({ theme }) => (theme.device.mobileL)} {
  overflow: hidden;
  width: 320px;
  text-overflow: ellipsis;
  white-space: nowrap;
}
`;
const MediaContainer = styled.div`
  display: flex;
  @media ${({ theme }) => (theme.device.mobileL)} {
    flex-wrap: wrap;
  }
`;

const Image = styled.img`
  width: 100px;
  height: 100px;
`;

const TagBoxLabel = styled.div`
  display: flex;
  margin: 0 16px;
  flex-wrap: wrap;
  width: 350px;
  height: 100px;
  @media ${({ theme }) => (theme.device.mobileL)} {
    width: 270px;
    margin: 8px 0 0 0;
    height: fit-content;
  }
`;

const Card = ({
  image,
  content,
  isAdmin,
  toggleStage,
}) => {
  const { setIsComponentVisible, setImage } = useContext(ImageEditorContext);
  const { categories } = image;
  const categoriesKeys = Object.keys(categories);
  const handlerClick = () => {
    setIsComponentVisible(true);
    setImage(image);
  };
  return (
    <Container
      onClick={(isAdmin && content !== ALL_CONTENT)
        ? () => {} : () => handlerClick()}
    >
      <RadioBox>
        {(content !== ALL_CONTENT && isAdmin) && (
        <Check
          type="checkbox"
          id={image.id}
          value={image.id}
          checked={image.checked}
          onChange={(e) => toggleStage(e)}
        />
        )}
        <Label>
          <b>Image name:</b>
        </Label>
        <Label>
          {image.name}
        </Label>
      </RadioBox>
      <MediaContainer
        onClick={(isAdmin && content !== ALL_CONTENT)
          ? () => handlerClick() : () => {}}
      >
        <Image src={image.imageUrl} />
        <TagBoxLabel>
          {categoriesKeys.map((category) => {
            if (categories[category].length) {
              return (
                <Chip
                  label={category}
                  inputs={categories[category]}
                />
              );
            }
            return '';
          })}
        </TagBoxLabel>
      </MediaContainer>
    </Container>
  );
};

Card.propTypes = {
  image: PropTypes.instanceOf(Object).isRequired,
  content: PropTypes.number.isRequired,
  isAdmin: PropTypes.bool.isRequired,
  toggleStage: PropTypes.func.isRequired,
};

export default Card;
