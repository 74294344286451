import React, { useState } from 'react';
import styled from 'styled-components';
import { centerFlex, container } from '../../styled/mixins';
import {
  Uploader,
  Checker,
  EditorModal,
} from './components';
import useComponentVisible from '../../hooks/useComponentVisible';

const Body = styled.div`
  ${centerFlex}
  padding-top: 60px;
`;

const Container = styled.div`
    ${container}
    display:flex;
    justify-content: center;
    flex-wrap: wrap;
`;

const Images = () => {
  const [images, setImages] = useState([]);
  const [selectedUrl, setSelectedUrl] = useState('');
  const { isComponentVisible, setIsComponentVisible, ref } = useComponentVisible(false);
  return (
    <Body>
      <Container>
        <EditorModal
          isOpen={isComponentVisible}
          setIsOpen={setIsComponentVisible}
          selectedUrl={selectedUrl}
          setSelectedUrl={setSelectedUrl}
          modalRef={ref}
        />
        <Uploader setImages={setImages} images={images} />
        <Checker
          setImages={setImages}
          images={images}
          setIsOpen={setIsComponentVisible}
          setSelectedUrl={setSelectedUrl}
        />
      </Container>
    </Body>
  );
};

export default Images;
