import React, { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import styled from 'styled-components';
import { centerFlex, container } from '../styled/mixins';
import PIPER from '../assets/pipers';
import { SocialPiperLogo } from '../assets/logos';
import Auth from '../services/Auth.provider';

const Body = styled.div`
  ${centerFlex}
  background: ${({ theme }) => (theme.colors.secondary)};
  height: 60px;
  position:fixed;
  width: 100%;
  z-index: 100;
  box-shadow: rgba(0, 0, 0, 0.15) 0px 2px 16px;
`;

const Container = styled.div`
    ${container}
    display:flex;
    justify-content: space-between;
    padding: 0 24px;
`;

const MediaContainer = styled.div`
  display: flex;
  align-items: center;
`;

const Image = styled.img`
  width: 32px;
  heigth: 32px;
  margin-top: -6px;
`;
const Brand = styled.img`
  display: flex;
  align-items: center;
  margin: 8px;
`;
const SectionContainer = styled.div`
  display: flex;
  heigth: 100%
`;
const SectionLink = styled.a`
  margin: 4px 10px;
  padding: 0 5px;
  font-family: Open Sans;
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 22px;
  text-decoration: none;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  color: ${({ theme }) => (theme.colors.black)};
  &:hover {
    border-bottom: 4px solid ${({ theme }) => (theme.colors.black)};
  }
  border-bottom: ${({ theme, active }) => (active ? `4px solid ${theme.colors.black}` : '')};
`;
const PRIVATE_ROUTES = [
  {
    id: 1,
    name: 'IMAGES',
    path: '/images',
    active: false,
  },
  {
    id: 2,
    name: 'TAGGING',
    path: '/tagging',
    active: false,
  },
  {
    id: 3,
    name: 'LOGOUT',
    path: '/logout',
    active: false,
  },
];
const PUBLIC_ROUTES = [
  {
    id: 1,
    name: 'LOGIN',
    path: '/login',
    active: false,
  },
];

const NavBar = () => {
  const location = useLocation();
  const [routes, setRoutes] = useState([]);
  useEffect(() => {
    const getRoutes = async () => {
      const res = await Auth.isAuthenticated();
      const { success } = res;
      let newRoutes = success ? PRIVATE_ROUTES : PUBLIC_ROUTES;
      newRoutes = newRoutes.map((route) => {
        const newRoute = route;
        if (newRoute.path === location.pathname) {
          newRoute.active = true;
        } else {
          newRoute.active = false;
        }
        return newRoute;
      });
      setRoutes(newRoutes);
    };
    getRoutes();
  }, [location]);

  return (
    <Body>
      <Container>
        <MediaContainer>
          <Image src={PIPER.headBackground} />
          <Brand src={SocialPiperLogo} />
        </MediaContainer>
        <SectionContainer>
          {routes.map((route) => (
            <SectionLink
              key={route.id}
              href={route.path}
              active={route.active}
            >
              {route.name}
            </SectionLink>
          ))}
        </SectionContainer>
      </Container>
    </Body>
  );
};

export default NavBar;
