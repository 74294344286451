import React, { useState, useContext, useEffect } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { AlertsContext } from '../../../contexts/AlertsContext';
import {
  InputLine, Button, Tab, CheckboxLine,
} from '../../../components';
import { SUCCESS_UPDATE_IMAGE, ERROR_UPDATE_IMAGE } from '../../../utils/constants/alerts';
import { ImageProvider } from '../../../services/entities';
import capitalize from '../../../utils/functions/capitalize';

const ContainerInputs = styled.div`
  overflow: scroll;
  padding: 0 8px;
  transition: 0.2s;
  ${({ open }) => (!open ? 'max-height: 0;' : 'max-height: 270px;')}
  @media ${({ theme }) => (theme.device.tablet)} {
    ${({ open }) => (!open ? 'max-height: 0;' : 'max-height: 250px;')}
  }
`;

const CustomButton = styled(Button)`
  && {
    margin: 8px 0;
    background: ${({ theme }) => (theme.colors.gray60)};
    ${({ open }) => (open ? 'display: flex; justify-content: center;' : 'display: none;')}
  }
`;

const AuthorForm = ({
  image,
  setImage,
  setImages,
  images,
  className,
  handlerOpen,
  open,
}) => {
  const [isLoading, setIsLoading] = useState(false);
  const { useToasts } = useContext(AlertsContext);
  const { addToast } = useToasts();
  const [newImage, setNewImage] = useState({
    designer: '',
    copy: '',
    translator: '',
    resPhotoAuthor: '',
    resPhotoCredit: '',
    resPhotoRoyaltyFree: false,
    resPhotoSource: '',
    resVecAuthor: '',
    resVecCredit: '',
    resVecRoyaltyFree: false,
    resVecSource: '',
    resTypoAuthor: '',
    resTypoCredit: '',
    resTypoRoyaltyFree: false,
    resTypogSource: '',
  });

  useEffect(() => {
    setNewImage({
      designer: image.designer,
      copy: image.copy,
      translator: image.translator,
      resPhotoAuthor: image.resPhotoAuthor,
      resPhotoCredit: image.resPhotoCredit,
      resPhotoRoyaltyFree: image.resPhotoRoyaltyFree,
      resPhotoSource: image.resPhotoSource,
      resVecAuthor: image.resVecAuthor,
      resVecCredit: image.resVecCredit,
      resVecRoyaltyFree: image.resVecRoyaltyFree,
      resVecSource: image.resVecSource,
      resTypoAuthor: image.resTypoAuthor,
      resTypoCredit: image.resTypoCredit,
      resTypoRoyaltyFree: image.resTypoRoyaltyFree,
      resTypogSource: image.resTypogSource,
    });
  }, [image]);

  const handleInputChange = (e) => {
    const { id, value } = e.target;
    setNewImage({
      ...newImage,
      [id]: capitalize(value),
    });
  };
  const handlerCheck = (e) => {
    const { id, checked } = e.target;
    setNewImage({
      ...newImage,
      [id]: checked,
    });
  };

  const handlerOnclick = async () => {
    setIsLoading(true);
    const { success } = await ImageProvider.editImage(image.id, null, null, newImage, null, null);
    if (success) {
      const newImages = [...images].map((i) => {
        if (i.id === image.id) {
          return {
            ...i,
            ...newImage,
          };
        }
        return i;
      });
      setImages([...newImages]);
      setImage({
        ...image,
        ...newImage,
      });
      addToast(SUCCESS_UPDATE_IMAGE, { appearance: 'success', autoDismiss: true });
    } else {
      addToast(ERROR_UPDATE_IMAGE, { appearance: 'error', autoDismiss: true });
    }
    setIsLoading(false);
    handlerOpen();
  };

  return (
    <Tab
      title="Author information"
      open={open}
      handlerOpen={handlerOpen}
    >
      <>
        <ContainerInputs
          className={className}
          open={open}
        >
          <InputLine
            id="designer"
            onChange={handleInputChange}
            label="Designer"
            value={newImage.designer}
          />
          <InputLine
            id="copy"
            onChange={handleInputChange}
            label="Copy"
            value={newImage.copy}
          />
          <InputLine
            id="translator"
            onChange={handleInputChange}
            label="Translator"
            value={newImage.translator}
          />
          <InputLine
            id="resPhotoAuthor"
            onChange={handleInputChange}
            label="Author of the photographic resource"
            value={newImage.resPhotoAuthor}
          />
          <InputLine
            id="resPhotoCredit"
            onChange={handleInputChange}
            label="Photo resource credit"
            value={newImage.resPhotoCredit}
          />
          <CheckboxLine
            id="resPhotoRoyaltyFree"
            onChange={handlerCheck}
            label="Photo resource Royalty free"
            checked={newImage.resPhotoRoyaltyFree}
          />
          <InputLine
            id="resPhotoSource"
            onChange={handleInputChange}
            label="Source data bank"
            value={newImage.resPhotoSource}
          />
          <InputLine
            id="resVecAuthor"
            onChange={handleInputChange}
            label="Vector resource author"
            value={newImage.resVecAuthor}
          />
          <InputLine
            id="resVecCredit"
            onChange={handleInputChange}
            label="Vector Resource Credit"
            value={newImage.resVecCredit}
          />
          <CheckboxLine
            id="resVecRoyaltyFree"
            onChange={handlerCheck}
            label="Vector resource Royalty free"
            checked={newImage.resVecRoyaltyFree}
          />
          <InputLine
            id="resVecSource"
            onChange={handleInputChange}
            label="Vector Resource bank"
            value={newImage.resVecSource}
          />
          <InputLine
            id="resTypoAuthor"
            onChange={handleInputChange}
            label="Author of the thiographic resource"
            value={newImage.resTypoAuthor}
          />
          <InputLine
            id="resTypoCredit"
            onChange={handleInputChange}
            label="Typographic Resource Credit"
            value={newImage.resTypoCredit}
          />
          <CheckboxLine
            id="resTypoRoyaltyFree"
            onChange={handlerCheck}
            label="Typo resource Royalty free"
            checked={newImage.resTypoRoyaltyFree}
          />
          <InputLine
            id="resTypogSource"
            onChange={handleInputChange}
            label="Source data bank"
            value={newImage.resTypogSource}
          />
        </ContainerInputs>
        <CustomButton
          open={open}
          onClick={handlerOnclick}
          isLoading={isLoading}
        >
          Save
        </CustomButton>
      </>
    </Tab>
  );
};

AuthorForm.propTypes = {
  image: PropTypes.shape({
    id: PropTypes.string,
    name: PropTypes.string,
    tags: PropTypes.oneOfType(Array),
    imageUrl: PropTypes.string,
    imageType: PropTypes.string,
    designer: PropTypes.string,
    copy: PropTypes.string,
    translator: PropTypes.string,
    resPhotoAuthor: PropTypes.string,
    resPhotoCredit: PropTypes.string,
    resPhotoRoyaltyFree: PropTypes.bool,
    resPhotoSource: PropTypes.string,
    resVecAuthor: PropTypes.string,
    resVecCredit: PropTypes.string,
    resVecRoyaltyFree: PropTypes.bool,
    resVecSource: PropTypes.string,
    resTypoAuthor: PropTypes.string,
    resTypoCredit: PropTypes.string,
    resTypoRoyaltyFree: PropTypes.bool,
    resTypogSource: PropTypes.string,
  }).isRequired,
  setImages: PropTypes.func.isRequired,
  setImage: PropTypes.func.isRequired,
  images: PropTypes.oneOfType(Array).isRequired,
  className: PropTypes.string,
  handlerOpen: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
};

AuthorForm.defaultProps = {
  className: '',
};

export default AuthorForm;
