import * as Sentry from '@sentry/react';
import appsyncClient from '../appsync/AppsyncClient';
import { createAdminUser } from '../../graphql/mutations';

export default class AdminProvider {
  static async create(name, lastName, email) {
    const result = {
      message: '',
      success: false,
      data: false,
    };
    try {
      await appsyncClient.mutate({
        mutation: createAdminUser,
        variables: {
          adminUserSettings: {
            firstName: name,
            lastName,
            email,
            authId: 'Cognito',
          },
        },
      });
      result.success = true;
      result.data = true;
    } catch (err) {
      result.message = err.message;
      Sentry.captureException(err);
    }
    return result;
  }

  static async isAdmin() {
    const group = window.localStorage.getItem('userGroups');
    if (group === 'Admins') {
      return true;
    }
    return false;
  }
}
