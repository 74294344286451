import gql from 'graphql-tag';

export const tags = gql`
query getTags ($sort:[[String]], $where: String, $categoryId: ID){
  tags(sort: $sort, where: $where, categoryId: $categoryId) {
    id
    name
    nameEs
  }
}
`;

export const getTemplates = (attr = `
  name
`) => gql`
query getTemplates($tagName: String, $pagination: Pagination, $orderBy: ORDER_BY, $language: LANGUAGE, $imageName: String, $production: Boolean) {
  getTemplates(tagName: $tagName, pagination: $pagination, orderBy: $orderBy, language: $language, production: $production, imageName: $imageName) {
      items {
        id
        name
        tags {
          ${attr}
          id
          categoryId
          category
        }
        imageUrl
        imageUuid
        imageType
        isPremium
        resPhotoAuthor
        resPhotoCredit
        resPhotoRoyaltyFree
        resPhotoSource
        resVecAuthor
        resVecCredit
        resVecRoyaltyFree
        resVecSource
        resTypoAuthor
        resTypoCredit
        resTypoRoyaltyFree
        resTypogSource
        language
      }
      count
    }
  }
`;

export const getAllTemplates = (attr = `
  name
`) => gql`
  query getTemplates($tagName: String, $pagination: Pagination, $imageName: String, $orderBy: ORDER_BY, $language: LANGUAGE) {
    getTemplates(tagName: $tagName, pagination: $pagination, orderBy: $orderBy, language: $language, imageName: $imageName) {
      items {
        id
        name
        tags {
          ${attr}
          id
          categoryId
          category
        }
        imageUrl
        imageUuid
        imageType
        isPremium
        designer
        copy
        translator
        resPhotoAuthor
        resPhotoCredit
        resPhotoRoyaltyFree
        resPhotoSource
        resVecAuthor
        resVecCredit
        resVecRoyaltyFree
        resVecSource
        resTypoAuthor
        resTypoCredit
        resTypoRoyaltyFree
        resTypogSource
        language
      }
      count
    }
  }
`;

export const getUploadUrls = gql`
  query getUploadUrls($inputs: [UploadUrlInput!], $isTemplate:Boolean) {
    getUploadUrls(inputs:$inputs, isTemplate:$isTemplate) {
      uploadUrl
      imageName
    }
  }
`;
