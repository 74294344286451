import React, { useState, useEffect, useContext } from 'react';
import styled from 'styled-components';
import AddTags from './AddTags';
import AllTags from './AllTags';
import { ImageEditorContext } from '../../../contexts/ImageEditorContext';
import { TagsProvider, CategoryProvider } from '../../../services/entities';

const Container = styled.div`
    display: flex;
    width: 50%;
    padding: 16px;
    flex-direction: column;
    @media ${({ theme }) => (theme.device.tablet)} {
      width: 100%;
    }
`;

const Tags = () => {
  const [tags, setTags] = useState([]);
  const [tag, setTag] = useState({});
  const [language, setLanguage] = useState('en');
  const [categoryId, setCategoryId] = useState(0);
  const [categories, setCategories] = useState([]);
  const { setLuCategories } = useContext(ImageEditorContext);
  useEffect(() => {
    const getTags = async () => {
      const wordToSearch = tag.value || '';
      const result = await TagsProvider.search(wordToSearch, language, categoryId);
      const { success, data } = result;
      if (success) {
        setTags(data);
      }
    };
    getTags();
  }, [language, tag, categoryId]);
  useEffect(() => {
    const getCategories = async () => {
      const { data, success } = await CategoryProvider.fetch();
      if (success) {
        setLuCategories(data);
        const categoriesOptions = data.map((c) => ({
          id: c.id,
          label: c.name,
          value: c.id,
        }));
        setCategories([
          {
            id: 0,
            label: 'All',
            value: 0,
          },
          ...categoriesOptions,
        ]);
      }
    };
    getCategories();
    // eslint-disable-next-line
  }, []);
  return (
    <Container>
      <AddTags
        tags={tags}
        setTags={setTags}
      />
      <AllTags
        tags={tags}
        setTags={setTags}
        setLanguage={setLanguage}
        language={language}
        setTag={setTag}
        tag={tag}
        categories={categories}
        categoryId={categoryId}
        setCategoryId={setCategoryId}
      />
    </Container>
  );
};

export default Tags;
