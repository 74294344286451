import { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import Auth from '../services/Auth.provider';

const Logout = () => {
  const history = useHistory();
  useEffect(() => {
    const logout = async () => {
      await Auth.signOut();
      history.push({ pathname: '/login' });
    };
    logout();
  }, [history]);
  return null;
};

export default Logout;
