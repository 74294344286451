import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { input } from '../styled/mixins';

const InputHolder = styled.div`
  width:100%;
  margin-top: 1em;
  font-size: 16px;
  margin-bottom: 1em;
  width: 100%;
`;

const LabelInput = styled.div`
  line-height: 1em;
  font-weight: 600;
  margin-bottom: .5em;
  display: flex;
`;

const InputStyled = styled.input`
  width:100%;
  ${input}
  color: ${({ theme }) => theme.colors.black};
  :focus{
    border-color:${({ theme }) => theme.colors.primary};
  }
`;

const Warning = styled.div`
  font-size: 14px;
  line-height: 20px;
  color: ${({ theme }) => theme.colors.gray90};
`;

const Required = styled.div`
  font-family: ${({ theme }) => theme.font.font1};
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 24px;
  font-feature-settings: 'pnum' on, 'lnum' on;
  color: ${({ theme }) => theme.colors.orange};
  display: inline-block;
  margin-top: -5px;
  margin-bottom: 5px;
  margin-left: 2px;
`;

const Input = ({
  onChange,
  helper,
  label,
  value,
  placeholder,
  id,
  type,
  required,
  className,
}) => {
  const onChangeHolder = (evt) => {
    onChange(evt);
  };

  return (
    <InputHolder className={className}>
      {label && (
        <LabelInput>
          {label}{required && <Required>*</Required>}
        </LabelInput>
      )}
      <InputStyled
        onChange={(e) => onChangeHolder(e)}
        placeholder={placeholder || label}
        value={value || ''}
        id={id}
        type={type}
      />
      {helper && <Warning>{helper}</Warning>}
    </InputHolder>
  );
};

Input.propTypes = {
  helper: PropTypes.string,
  id: PropTypes.string,
  label: PropTypes.string,
  onChange: PropTypes.func,
  placeholder: PropTypes.string,
  required: PropTypes.bool,
  type: PropTypes.string,
  value: PropTypes.string,
  className: PropTypes.string,
};

Input.defaultProps = {
  onChange: () => {},
  helper: '',
  placeholder: ' ',
  label: '',
  value: '',
  id: '',
  type: 'text',
  required: false,
  className: '',
};

export default Input;
