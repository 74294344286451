import React from 'react';
import {
  BrowserRouter as Router, Switch, Route, Redirect,
} from 'react-router-dom';
import Tagging from './views/Tagging';
import Login from './views/Login';
import Logout from './components/Logout';
import NavBar from './components/NavBar';
import Images from './views/Images';
import { PrivateRoute, PublicRoute } from './helpers';
import './App.css';

function App() {
  return (
    <Router>
      <NavBar />
      <Switch>
        <Route path="/" exact>
          <Redirect to="/tagging" />
        </Route>
        <PrivateRoute path="/tagging" component={Tagging} />
        <PrivateRoute path="/images" component={Images} />
        <PrivateRoute path="/logout" component={Logout} />
        <PublicRoute path="/login" component={Login} />
      </Switch>
    </Router>
  );
}

export default App;
