import React, { useRef } from 'react';
import PropTypes from 'prop-types';
import SPDesigner from 'socialpiper-react-designer';
import styled from 'styled-components';
import { centerFlex, container } from '../../styled/mixins';
import {
  EDITOR_MAX_UPLOAD_SIZE,
  CAPTIONS,
  LOGO,
  LOGO_BO,
} from './constants';
import { BlankHolderIcon, UploadHolderIcon } from '../../assets/icons';

const Body = styled.div`
  ${centerFlex}
  width: 700px;
  @media ${({ theme }) => (theme.device.tablet)} {
    width: 570px;
  }
`;

const Container = styled.div`
    ${container}
    display:flex;
    justify-content: center;
    flex-wrap: wrap;
`;

const Editor = ({ url }) => {
  const editorRef = useRef();
  return (
    <Body>
      <Container>
        <SPDesigner
          ref={editorRef}
          initialUrl={url}
          uriLogo={LOGO_BO}
          piperLogo={LOGO}
          uploadHolderIco={UploadHolderIcon}
          blankHolderIcos={BlankHolderIcon}
          piperHelp={LOGO}
          captions={CAPTIONS}
          maxUploadSize={EDITOR_MAX_UPLOAD_SIZE}
          // eslint-disable-next-line no-console
          callBackEmptyCanva={() => console.log('>>>>CallbackEmpyCanva')}
          // eslint-disable-next-line no-console
          callBackNewImg={() => console.log('>>>>>callBackNewImgSuperDuperxx')}
          isPro
        />
      </Container>
    </Body>
  );
};

Editor.propTypes = {
  url: PropTypes.string.isRequired,
};

export default Editor;
