import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { centerFlex } from '../../styled/mixins';
import { CloseIcon } from '../../assets/icons';

const Container = styled.div`
  ${centerFlex}
  background-color: rgba(0, 0, 0, 0.5);
  width: 100%;
  height: 100%;
  position: fixed;
  overflow: hidden;
  z-index: 10;
  ${({ isOpen }) => (isOpen ? 'display: flex;' : 'display: none;')}
`;

const Card = styled.div`
  background-color: white;
  width: 100%;
  height: 100%;
  padding: 8px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  position: relative;
`;

const CloseNav = styled.div`
  width: 100%;
  height: 36px;
  cursor: pointer;
  display flex;
  padding: 32px;
  justify-content: flex-end;
  position: absolute;
  top: 0;
  max-width: 1424px;
  margin-top: 0;
  margin-bottom: 0;
`;
const IconBox = styled.a``;

const Icon = styled.img`
  height: 32px;
  width: 32px;
`;

const Modal = ({
  isOpen,
  className,
  handlerOnClick,
  children,
  modalRef,
}) => (
  <Container
    isOpen={isOpen}
  >
    <Card ref={modalRef} className={className}>
      <CloseNav>
        <IconBox onClick={handlerOnClick}>
          <Icon
            src={CloseIcon}
          />
        </IconBox>
      </CloseNav>
      {children}
    </Card>
  </Container>
);

Modal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  handlerOnClick: PropTypes.func.isRequired,
  className: PropTypes.string,
  children: PropTypes.node.isRequired,
  modalRef: PropTypes.oneOfType([PropTypes.shape({
    current: PropTypes.object,
  }), PropTypes.func]).isRequired,
};

Modal.defaultProps = {
  className: '',
};

export default Modal;
