import React from 'react';
import styled from 'styled-components';
import { header } from '../../../styled/mixins';
import piper from '../../../assets/pipers';

const Container = styled.div`
  display: flex;
  justify-content: center;
  padding-top: 60px;
  width: 100%;
  @media ${({ theme }) => (theme.device.mobileL)} {
    width: 100%;
    background: ${({ theme }) => (theme.colors.secondary)};
    padding: 20px 0;
  }
`;

const HeaderContainer = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  flex-direction: column;
  max-width: 392px;
  width: 100%;
@media ${({ theme }) => (theme.device.mobileL)} {
  flex-direction: row-reverse;
  justify-content: space-between;
}
`;

const Heading = styled.h1`
  ${header}
  margin-bottom: 16px;
  @media ${({ theme }) => (theme.device.tablet)} {
    font-size: 30px;
  }
  @media ${({ theme }) => (theme.device.mobileL)} {
    text-align: center;
    font-size: 26px;
    width: 200px;
  }
`;

const PiperContainer = styled.div`
  position: absolute;
  right: 100%;
  height: 400px;
  width: 400px;
  @media ${({ theme }) => (theme.device.laptop)} {
    width: 350px;
  }
  @media ${({ theme }) => (theme.device.tablet)} {
    display: block;
    position: static;
    height: 200px;
  }
  @media ${({ theme }) => (theme.device.mobileL)} {
    flex-direction: row;
    width:120px;
    height: auto;
    margin-right: 10px;
  }
`;
const Image = styled.img`
width: 350px;
@media ${({ theme }) => (theme.device.laptop)} {
    width: 320px;
}
@media ${({ theme }) => (theme.device.mobileL)} {
    width: 200px;
}
`;

const Header = () => (
  <Container>
    <HeaderContainer>
      <Heading>
        Welcome to Backoffice!
      </Heading>
      <PiperContainer>
        <Image src={piper.tools} />
      </PiperContainer>
    </HeaderContainer>
  </Container>
);

export default Header;
