/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable react/prop-types */
import React from 'react';
import { Redirect, Route } from 'react-router-dom';

const PublicRoute = ({ component: Component, ...rest }) => (
  <Route
    {...rest}
    render={(props) => (!window.localStorage.getItem('userEmail') ? (
      <Component {...props} />
    ) : (
      <Redirect
        to={{
          pathname: '/tagging',
          state: { from: props.location },
        }}
      />
    ))}
  />
);

export default PublicRoute;
