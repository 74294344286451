import React from 'react';
import styled from 'styled-components';
import { centerFlex, container } from '../../styled/mixins';
import { Form, Header } from './components';

const Body = styled.div`
  ${centerFlex}
  padding-top: 60px;
`;

const Container = styled.div`
    ${container}
    display:flex;
    justify-content: center;
    flex-wrap: wrap;
`;

const Login = () => (
  <Body>
    <Container>
      <Header />
      <Form />
    </Container>
  </Body>
);

export default Login;
