import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { CloseBubbleIcon } from '../assets/icons';

const Container = styled.div`
  padding: 24px;
  top: 0;
  left: 0;
  transition: transform .5s ease;
  transform: ${({ isComponentVisible }) => (isComponentVisible ? 'translateX(0%);' : 'translateX(-100%);')};
  width: 400px;
  background: ${({ theme }) => (theme.colors.gray60)};
  height: 100%;
  position: fixed;
  z-index: 10;
  @media ${({ theme }) => (theme.device.mobileL)} {
    width: 100%;
  }
`;

const CloseNav = styled.div`
  margin-top: 60px;
  width: 100%;
  height: 36px;
  cursor: pointer;
  display flex;
  justify-content: space-between;
`;
const IconBox = styled.a``;

const Icon = styled.img`
  width: 36px;
  height: 36px;
`;

const Title = styled.h2`
  color: ${({ theme }) => (theme.colors.white)};
  font-weight: 600;
  font-size: 16px;
  text-transform: uppercase;
`;

const DrawerMenu = ({
  className,
  children,
  drawerMenuRef,
  isComponentVisible,
  setIsComponentVisible,
  title,
}) => (
  <Container
    isComponentVisible={isComponentVisible}
    ref={drawerMenuRef}
    className={className}
  >
    <CloseNav>
      <Title>
        {title}
      </Title>
      <IconBox onClick={() => { setIsComponentVisible(false); }}>
        <Icon src={CloseBubbleIcon} />
      </IconBox>
    </CloseNav>
    {children}
  </Container>
);

DrawerMenu.propTypes = {
  className: PropTypes.string,
  children: PropTypes.node,
  drawerMenuRef: PropTypes.oneOfType([PropTypes.shape({
    current: PropTypes.object,
  }), PropTypes.func]).isRequired,
  isComponentVisible: PropTypes.bool.isRequired,
  setIsComponentVisible: PropTypes.func.isRequired,
  title: PropTypes.string,
};

DrawerMenu.defaultProps = {
  className: '',
  children: (<></>),
  title: '',
};
export default DrawerMenu;
