import { css } from 'styled-components';

export const container = () => css`
   width: 100%;
   max-width: 1424px;
   margin-top: 0;
   margin-bottom: 0;
`;

export const centerFlex = () => css`
   display: flex;
   justify-content: center;
   align-items: center;
`;
export const header = () => css`
  font-size: 48px;
  font-weight: 600;
  margin: 0;
`;
export const title = () => css`
  font-size: 22px;
  font-weight: 600;
`;

export const subTitle = () => css`
  font-size: 20px;
  font-weight: 600;
`;

export const label = () => css`
  font-size: 16px;
  font-weight: 600;
`;

export const input = () => css`
  height: 40px;
  padding: 7px 8px;
  font-size: 18px;
  color:  ${({ theme }) => theme.colors.gray02};
  border: 1px solid ${({ theme }) => theme.colors.gray02};
  border-radius: 4px;
  font-family: ${({ theme }) => theme.font.font1};
`;

export const colorBorderBox = () => css`
  box-shadow: 0px 2px 16px rgba(0, 0, 0, 0.15);
  border-top: 8px solid ${({ theme, color }) => color || theme.colors.lightBlue};
  background: rgb(255, 255, 255);
  padding: 16px 24px;
`;
