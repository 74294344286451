const colors = {
  black: '#231F20',
  text02: '#675458',
  primary: '#027686',
  primaryDark: 'rgb(1, 82, 93)',
  primaryLight: '#00899B',
  primaryLighter: '#009DB2',
  secondary: '#FFDD00',
  speechBubble: '#009DB2',
  secondaryDark: '#FEBD11',
  rocketPiperBackground: '#FED601',
  screenBackground: '#F8F8F8',
  headerTabInactive: '#FFEE80',
  purple: '#8D4A80',
  lightPurple: '#B962A8',
  lightYellow: 'rgba(255, 221, 0, 0.25)',
  orange: '#FF7E4E',
  white: '#FFFFFF',
  grayF1: '#F1F1F1',
  gray01: '#f4f4f4',
  gray02: '#909090',
  grayE9: '#E9E9E9',
  gray90: '#909090',
  grayMediaLibrary: '#3C3C3C',
  grayF5: '#F5F5F5',
  chartGray: '#675458',
  badge: '#EB5B62',
  error: '#ED1C24',
  green: '#00BF62',
  red: '#FF4D5D',
  redDark: '#bf2e3b',
  blue: '#415DA5',
  brand5: '#F053AD',
  brand8: '#00D6C8',
  previewBack: '#3B3B3B',
  grayedPurple: '#B1A2A2',
  lightBlue: '#00B4CC',
  gray60: '#3c3c3c',
};

export default colors;
