import React from 'react';
import PropTypes from 'prop-types';
import { Editor, Modal } from '../../../components';

const EditorModal = ({
  isOpen,
  setIsOpen,
  selectedUrl,
  setSelectedUrl,
  modalRef,
}) => {
  const handlerOnClick = () => {
    setIsOpen(!isOpen);
    setSelectedUrl('');
  };

  return (
    !!selectedUrl && (
    <Modal
      handlerOnClick={handlerOnClick}
      isOpen={isOpen}
      modalRef={modalRef}
    >
      <Editor url={selectedUrl} />
    </Modal>
    )
  );
};

EditorModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  setIsOpen: PropTypes.func.isRequired,
  selectedUrl: PropTypes.string.isRequired,
  setSelectedUrl: PropTypes.func.isRequired,
  modalRef: PropTypes.oneOfType([PropTypes.shape({
    current: PropTypes.object,
  }), PropTypes.func]).isRequired,
};

export default EditorModal;
