import React from 'react';
import styled from 'styled-components';
import { Tags, Images } from './components';
import { centerFlex, container } from '../../styled/mixins';
import ImageEditorProvider from '../../contexts/ImageEditorContext';

const Body = styled.div`
  ${centerFlex}
  padding-top: 60px;
`;

const Container = styled.div`
    ${container}
    display:flex;
    justify-content: center;
    flex-wrap: wrap;
`;

const Tagging = () => (
  <Body>
    <Container>
      <ImageEditorProvider>
        <Tags />
        <Images />
      </ImageEditorProvider>
    </Container>
  </Body>
);

export default Tagging;
