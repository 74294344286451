export const EDITOR_MAX_UPLOAD_SIZE = 3;
export const LOGO = 'https://dev-5d8c82da-socialpiper-cdn.s3.amazonaws.com/logos/5eb1962655da15132169fea9/b6f7a480-c24b-11ea-8629-e1edf4dc0055.png';
export const LOGO_BO = 'https://dev-5d8c82da-socialpiper-cdn.s3.amazonaws.com/logos/60496db5e267e30069be62fa/836ea100-91ac-11eb-9879-e5aa37c24ff2.jpg';
export const CAPTIONS = {
  WELCOME_TITLE: 'How would you like to start creating your template?',
  WELCOME_LOADING_IMAGE: 'Loading...',
  WELCOME_EMPTY_TEXT: 'Start with a brand new canvas.',
  WELCOME_UPLOAD_TEXT: 'I want to upload my own image.',
  WELCOME_UPLOAD_HELP: ' You can drag & drop here a JPG or PNG file or just click here to select it from your device.',
  WELCOME_UPLOAD_FAIL: 'Can drop only one file at the same time',
  WELCOME_UPLOAD_SIZE_FAIL: 'File upload limit is',
  HELP_EDIT_1: 'To edit this element just double click',
  HELP_TEXT_INSERT: 'Click on the canvas to place a text line',
  HELP_TEXT_TYPING: 'Start typing, if you want finish click on another place',
  HELP_OBJECT_DRAW: 'Click on the canvas to start drawing',
  HELP_IMAGE_DRAW: 'Click on the canvas to place the image',
  HELP_POLYGON_CLOSE: 'you don\'t want to close the polygon press enter.',
  PANEL_FONT_STYLE: 'Font Style',
  PANEL_FONT_TEXT: 'Text',
  PANEL_POSITION: 'Position',
  PANEL_ROTATION: 'Rotation',
  PANEL_SIZE: 'Size:',
  PANEL_FILL: 'Fill',
  PANEL_STROKE: 'Stroke',
  PANEL_WIDTH: 'Width:',
  PANEL_HEIGHT: 'Height:',
  PANEL_BORDER_RADIUS: 'Border Radius:',
  PANEL_OPACITY: 'Opacity',
  PANEL_IMAGE: 'Image',
  PANEL_IMAGE_DROP: 'Click or Drop Here',
  PANEL_ARRANGE_STB: 'Send to Back',
  PANEL_ARRANGE_STF: 'Send to Front',
};
