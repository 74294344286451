import * as Sentry from '@sentry/react';
import axios from 'axios';
import { v1 as uuidv1 } from 'uuid';
import decode from 'unescape';
import { ImagesMock } from '../../mocks/entities';
import {
  ENVIRONMENTS,
  ENV,
  LANGUAGES_SUFIX,
  CONTENT_PATH,
} from '../../utils/constants/globals';
import { getTemplates, getAllTemplates, getUploadUrls } from '../../graphql/queries';
import {
  updateImageStage,
  editImage as editImageMutation,
  saveTemplates,
  deleteImage,
} from '../../graphql/mutations';
import { ALL_CONTENT, PROD_CONTENT } from '../../views/Tagging/constant';
import appsyncClient from '../appsync/AppsyncClient';
import groupByKeys from '../../utils/functions/groupByKey';
import CategoryProvider from './Category.provider';

export default class ImageProvider {
  static async fetchByTag(limit = 10, offset = 0, tagName = '', language = 'en', stage = ALL_CONTENT, imageName = '') {
    let result;
    let count = 0;
    const columnName = `name${LANGUAGES_SUFIX[language]}`;
    let isAllTemplates = true;
    const variables = {
      tagName,
      language,
      imageName,
      pagination: {
        limit,
        offset,
      },
    };
    if (stage !== ALL_CONTENT) {
      variables.production = stage === PROD_CONTENT;
      isAllTemplates = false;
    }
    try {
      if (ENV !== ENVIRONMENTS.mock) {
        result = await appsyncClient.query({
          query: isAllTemplates ? getAllTemplates(columnName) : getTemplates(columnName),
          fetchPolicy: 'no-cache',
          variables,
        });
      } else {
        result = ImagesMock;
      }
      const luCategoriesResponse = await CategoryProvider.fetch();
      count = result.data.getTemplates.count;
      let categories = [];
      result = result.data.getTemplates.items.map((template) => {
        const { tags } = template;
        if (tags.length) {
          categories = groupByKeys(tags, 'category');
          const categoriesKeys = Object.keys(categories);
          luCategoriesResponse.data.forEach((luCat) => {
            if (categoriesKeys.indexOf(luCat.name) === -1) {
              categories[luCat.name] = [];
            } else {
              categories[luCat.name] = categories[luCat.name]
                .map((tag) => ({
                  ...tag,
                  label: tag[columnName],
                  value: tag.id,
                }));
            }
          });
        } else {
          const categoriesKeys = {};
          luCategoriesResponse.data.forEach((luCat) => {
            categoriesKeys[luCat.name] = [];
          });
          categories = categoriesKeys;
        }
        return {
          ...template,
          categories,
        };
      });
      result = {
        message: 'success',
        success: true,
        data: { count, items: result },
      };
    } catch (err) {
      result = {
        message: err.message,
        success: false,
        data: { count: 0, items: [] },
      };
      Sentry.captureException(err);
    }
    return result;
  }

  static async updateStage(ids, isProd) {
    const result = {
      message: '',
      success: false,
      data: false,
    };
    try {
      if (ENV !== ENVIRONMENTS.mock) {
        await appsyncClient.mutate({
          mutation: updateImageStage,
          fetchPolicy: 'no-cache',
          variables: {
            ids,
            production: isProd,
          },
        });
      }
      result.success = true;
      result.data = true;
    } catch (err) {
      result.message = err.message;
      Sentry.captureException(err);
    }
    return result;
  }

  static async editImage(id, name, categoryTags, authorInfo, fileName, imageUuid) {
    const result = {
      message: '',
      success: false,
      data: false,
    };
    try {
      if (ENV !== ENVIRONMENTS.mock) {
        const res = await appsyncClient.mutate({
          mutation: editImageMutation,
          fetchPolicy: 'no-cache',
          variables: {
            id,
            name,
            tags: categoryTags,
            authorInfo,
            fileName,
            imageUuid,
          },
        });
        result.data = res.data.editImage;
      }
      result.success = true;
    } catch (err) {
      result.message = err.message;
      Sentry.captureException(err);
    }
    return result;
  }

  static async getUploadUrls(images) {
    const inputs = images.map((img) => ({
      fileName: img.fileName,
      fileType: 'image/svg+xml',
      folder: CONTENT_PATH,
    }));
    let result = {
      message: '',
      success: false,
      data: null,
    };
    try {
      const response = await appsyncClient.query({
        query: getUploadUrls,
        variables: {
          inputs,
          isTemplate: true,
        },
      });
      const uploadUrls = response.data.getUploadUrls;
      const payload = uploadUrls.map((input, i) => ({
        decodedUrl: decode(input.uploadUrl),
        blob: images[i].blob,
      }));
      const options = {
        headers: {
          'Content-Type': 'image/svg+xml',
          'Cache-Control': 'no-cache',
        },
      };
      const promises = [];
      payload.forEach((input) => {
        promises.push(axios.put(input.decodedUrl, input.blob, options));
      });
      await Promise.all(promises);
      result = {
        message: 'success',
        success: true,
        data: uploadUrls,
      };
    } catch (err) {
      Sentry.captureException(err);
      result.message = err.message;
    }
    return result;
  }

  static async saveTemplates(images) {
    let result = {
      message: '',
      success: false,
      data: null,
    };
    const newImages = await Promise.all([...images].map(async (img) => {
      const uuid = uuidv1();
      const fileName = img.name
        .replace(/.svg/g, '')
        .replace(/[^a-zA-Z0-9]/g, '')
        .replace(/ /g, '');
      const newName = img.name.replace('.svg', '');
      const blob = await fetch(img.src).then((res) => res.blob());
      return {
        ...img,
        name: newName,
        fileName: `${uuid}-${fileName}.svg`,
        blob,
        imageUuid: uuid,
      };
    }));
    try {
      if (ENV === ENVIRONMENTS.mock) {
        return {
          message: '',
          success: true,
          data: true,
        };
      }
      const uploadUrlsResponse = await this.getUploadUrls(newImages);
      if (uploadUrlsResponse.success) {
        const payload = uploadUrlsResponse.data.map((svg, index) => ({
          fileName: svg.imageName,
          name: newImages[index].name,
          language: newImages[index].language,
          imageUuid: newImages[index].imageUuid,
        }));
        const saveTemplatesResult = await appsyncClient.mutate({
          mutation: saveTemplates,
          variables: {
            templates: payload,
          },
        });
        const templatesSaved = saveTemplatesResult.data.saveTemplates;
        if (templatesSaved) {
          result = {
            message: '',
            success: true,
            data: true,
          };
        }
      }
    } catch (err) {
      Sentry.captureException(err);
    }
    return result;
  }

  static async updateTemplate(image) {
    const result = {
      message: '',
      success: false,
      data: null,
    };
    const newImages = await Promise.all([image].map(async (img) => {
      const uuid = uuidv1();
      const fileName = img.name
        .replace(/.svg/g, '')
        .replace(/[^a-zA-Z0-9]/g, '')
        .replace(/ /g, '');
      const newName = img.name.replace('.svg', '');
      const blob = await fetch(img.src).then((res) => res.blob());
      return {
        ...img,
        name: newName,
        fileName: `${uuid}-${fileName}.svg`,
        blob,
        imageUuid: uuid,
      };
    }));
    try {
      if (ENV === ENVIRONMENTS.mock) {
        return {
          message: '',
          success: true,
          data: {
            imageUrl: 'https://cdn.stg.socialpiper.com/public-content/174b0340-baf4-11ea-bf0b-dfcb991f39ab-22juniomedicalawareness.svg',
          },
        };
      }
      const uploadUrlsResponse = await this.getUploadUrls(newImages);
      if (uploadUrlsResponse.success) {
        const [payload] = uploadUrlsResponse.data.map((svg, index) => ({
          fileName: svg.imageName,
          imageUuid: newImages[index].imageUuid,
        }));

        const response = await this.editImage(
          image.id, null, null, null, payload.fileName, payload.imageUuid,
        );
        const { success, data } = response;
        if (success) {
          result.success = true;
          result.data = data;
        }
      }
    } catch (err) {
      Sentry.captureException(err);
    }
    return result;
  }

  static async deleteImage(imageId) {
    const result = {
      message: '',
      success: false,
      data: null,
    };
    if (ENV === ENVIRONMENTS.mock) {
      return {
        message: '',
        success: true,
        data: true,
      };
    }
    try {
      const response = await appsyncClient.mutate({
        mutation: deleteImage,
        variables: {
          imageId,
        },
      });
      const deleted = response.data.deleteContent;
      if (deleted) {
        return {
          message: '',
          success: true,
          data: true,
        };
      }
    } catch (err) {
      result.message = err.message;
      Sentry.captureException(err);
    }
    return result;
  }
}
