import gql from 'graphql-tag';

export const getTagCategories = gql`
 query getTagCategories {
     getTagCategories {
     id
     name
     description
     hierarchy
     isRequired
     }
}
`;

export default {};
