import React, {
  useEffect, useState, useContext, useRef,
} from 'react';
import styled from 'styled-components';
import InfiniteScroll from 'react-infinite-scroller';
import { colorBorderBox, subTitle, centerFlex } from '../../../styled/mixins';
import { ImageProvider, TagsProvider, AdminProvider } from '../../../services/entities';
import {
  Input, Select, PiperAdvice, Button,
} from '../../../components';
import {
  LANGUAGE_LU, CONTENT_LU, LIMIT, ALL_CONTENT, DEFAULT_LANGUAGE, PROD_CONTENT,
} from '../constant';
import { LANGUAGES_SUFIX } from '../../../utils/constants/globals';
import { AlertsContext } from '../../../contexts/AlertsContext';
import Card from './Card';
import { SUCCES_ADDED_TO_STAGE } from '../../../utils/constants/alerts';
import { LoaderIcon } from '../../../assets/icons';
import { ImageEditorContext } from '../../../contexts/ImageEditorContext';

const Container = styled.div`
   display: flex;
   width: 50%;
   @media ${({ theme }) => (theme.device.tablet)} {
    width: 100%;
  }
`;

const ImageBox = styled.div`
  ${colorBorderBox}
  width: ${(({ heightCover }) => (heightCover ? 'calc(100%)' : 'calc(100% - 32px)'))};
  padding: 24px 16px;
  margin: 16px;
`;

const SubTitle = styled.h3`
  ${subTitle}
`;

const ImageContainer = styled.div`
  height: ${(({ heightCover }) => (heightCover ? '550px' : '500px'))};
  overflow: auto;
`;

const SearchBox = styled.div`
  margin: 16px 0;
  width: 300px;
  @media ${({ theme }) => (theme.device.mobileL)} {
    width: 100%;
  }
`;

const SearchContainer = styled.div`
    width: 100%;
    display: flex;
    justify-content: space-between;
    @media ${({ theme }) => (theme.device.mobileL)} {
      flex-wrap: wrap;
    }
`;
const ButtonContainer = styled.div`
    ${centerFlex}
    width:100%;
    padding: 8px 0;
`;
const CustomButton = styled(Button)`
&&{
  width: 300px;
  @media ${({ theme }) => (theme.device.mobileL)} {
    width: 100%;
  }
}
`;

const LoaderContainer = styled.div`
  display: flex;
  width: 100%;
  justify-content: center;
`;

const Loader = styled.img``;

const Label = styled.p`
  font-weight: 600;
  margin-top: 16px;
  margin-bottom: 0;
`;

const Images = () => {
  const [offset, setOffset] = useState(0);
  const [tag, setTag] = useState({});
  const { images, setImages } = useContext(ImageEditorContext);
  const [toSearch, setToSearch] = useState({
    tagName: '',
    imageName: '',
    language: DEFAULT_LANGUAGE,
    content: ALL_CONTENT,
  });
  const [imageToSearch, setImageToSearch] = useState('');
  const [language, setLanguage] = useState(DEFAULT_LANGUAGE);
  const [content, setContent] = useState(ALL_CONTENT);
  const [addToProdStage, setAddToProdStage] = useState(true);
  const [isAdmin, setIsAdmin] = useState(false);
  const [hasMore, setHasMore] = useState(false);
  const [scrollParentRef, setScrollParentRef] = useState(null);
  const [isLoadingSearchBtn, setIsLoadingSearchBtn] = useState(false);
  const [isLoadingStageBtn, setIsLoadingStageBtn] = useState(false);
  const { useToasts } = useContext(AlertsContext);
  const { addToast } = useToasts();
  const el = useRef(null);
  useEffect(() => {
    const getUserGroup = async () => {
      const isUserAdmin = await AdminProvider.isAdmin();
      setIsAdmin(isUserAdmin);
    };
    getUserGroup();
  }, []);

  useEffect(() => {
    const fetchTemplatesByTag = async () => {
      setIsLoadingSearchBtn(true);
      const wordToSearch = toSearch.tagName || '';
      const defaultOffset = 0;
      setOffset(defaultOffset);
      const result = await ImageProvider.fetchByTag(
        LIMIT,
        defaultOffset,
        wordToSearch,
        toSearch.language,
        toSearch.content,
        toSearch.imageName,
      );
      const { success, data } = result;
      if (success) {
        setOffset(data.items.length);
        const newImages = data.items.map((image) => ({ ...image, checked: false }));
        setImages(newImages);
        if (data.count === newImages.length) {
          setHasMore(false);
        } else {
          setHasMore(true);
        }
      } else {
        setHasMore(false);
      }
      setIsLoadingSearchBtn(false);
    };
    fetchTemplatesByTag();
  }, [toSearch, setImages]);

  const handleSearcherChange = (_, option) => {
    if (option) {
      setTag(option);
    } else {
      setTag({});
    }
  };

  const onChange = (e) => {
    const { value } = e.target;
    setImageToSearch(value);
  };

  const search = (() => {
    setToSearch({
      tagName: tag.value,
      imageName: imageToSearch.trim(),
      language,
      content,
    });
    setAddToProdStage(content !== PROD_CONTENT);
    el.current.scrollIntoView({ block: 'start', behavior: 'smooth' });
  });

  const handleLanguage = (_, option) => {
    if (option) {
      setLanguage(option.value);
    } else {
      setLanguage(DEFAULT_LANGUAGE);
    }
  };
  const handleContent = (_, option) => {
    if (option) {
      setContent(option.value);
    } else {
      setContent(ALL_CONTENT);
    }
  };
  const toggleStage = (e) => {
    let newImages = [...images];
    newImages = newImages.map((image) => {
      const newImage = { ...image };
      if (newImage.id === e.target.value) {
        newImage.checked = e.target.checked;
      }
      return newImage;
    });
    setImages(newImages);
  };
  const updateStage = async () => {
    setIsLoadingStageBtn(true);
    const newImages = [...images];
    const imageIds = newImages.filter((image) => image.checked).map((image) => image.id);
    const { success, message } = await ImageProvider.updateStage(imageIds, addToProdStage);
    if (success) {
      const noChecked = newImages.filter((image) => !image.checked);
      setImages(noChecked);
      addToast(SUCCES_ADDED_TO_STAGE, { appearance: 'success', autoDismiss: true });
    } else {
      addToast(message, { appearance: 'error', autoDismiss: true });
    }
    setIsLoadingStageBtn(false);
  };
  const getOptions = async (inputValue) => {
    const result = await TagsProvider.search(inputValue, language);
    const { data, success } = result;
    const columnName = `name${LANGUAGES_SUFIX[language]}`;
    if (success) {
      return data.map((option) => ({
        id: option.id,
        label: option[columnName],
        value: option[columnName],
      }));
    }
    return [];
  };

  const loadFunc = async () => {
    if (!hasMore && !offset) return;
    const wordToSearch = toSearch.tagName || '';
    const result = await ImageProvider.fetchByTag(
      LIMIT,
      offset,
      wordToSearch,
      toSearch.language,
      toSearch.content,
      toSearch.imageName,
    );
    const { success, data } = result;
    if (success) {
      let newImages = data.items.map((image) => ({ ...image, checked: false }));
      newImages = [...images, ...newImages];
      setImages(newImages);
      setOffset(newImages.length);
      if (data.count === newImages.length) {
        setHasMore(false);
      } else {
        setHasMore(true);
      }
    } else {
      setHasMore(false);
    }
  };

  return (
    <Container>
      <ImageBox heightCover={!(content !== ALL_CONTENT && isAdmin)}>
        <SubTitle>
          Images
        </SubTitle>
        <PiperAdvice>
          Select your filters
        </PiperAdvice>
        <SearchContainer>
          <SearchBox>
            <Label>Stage</Label>
            <Select
              onChange={handleContent}
              options={CONTENT_LU}
              id="contentId"
              value={CONTENT_LU.find((o) => o.value === content)}
            />
          </SearchBox>
          <SearchBox>
            <Label>Language</Label>
            <Select
              onChange={handleLanguage}
              options={LANGUAGE_LU}
              id="languageId"
              value={LANGUAGE_LU.find((o) => o.value === language)}
            />
          </SearchBox>
        </SearchContainer>
        <SearchContainer>
          <SearchBox>
            <Input
              label="Image name"
              id="imageToSearch"
              onChange={onChange}
              value={imageToSearch}
            />
          </SearchBox>
          <SearchBox>
            <Label>Tag name</Label>
            <Select
              value={tag}
              onChange={handleSearcherChange}
              loadOptions={getOptions}
              id="tagId"
              isAsync
              isSearchable
              isClearable
              placeholder="Search a tag..."
            />
          </SearchBox>
        </SearchContainer>
        <CustomButton
          disabled={isLoadingStageBtn}
          isLoading={isLoadingSearchBtn}
          onClick={() => search()}
        >
          Search
        </CustomButton>
        <ImageContainer
          ref={(ref) => setScrollParentRef(ref)}
          heightCover={!(content !== ALL_CONTENT && isAdmin)}
        >
          <div id="el" ref={el}>
            <InfiniteScroll
              pageStart={offset}
              loadMore={loadFunc}
              hasMore={hasMore}
              loader={(
                <LoaderContainer>
                  <Loader key={0} src={LoaderIcon} />
                </LoaderContainer>
              )}
              useWindow={false}
              getScrollParent={() => scrollParentRef}
            >
              {images.map((image) => (
                <Card
                  key={image.id}
                  image={image}
                  content={toSearch.content}
                  isAdmin={isAdmin}
                  toggleStage={toggleStage}
                />
              ))}
            </InfiniteScroll>
          </div>
        </ImageContainer>
        {(toSearch.content !== ALL_CONTENT && isAdmin) && (
        <ButtonContainer>
          <CustomButton
            disabled={isLoadingStageBtn}
            isLoading={isLoadingStageBtn}
            onClick={() => updateStage()}
          >
            Add Content to {addToProdStage ? 'production' : 'tagging' }
          </CustomButton>
        </ButtonContainer>
        )}
      </ImageBox>
    </Container>
  );
};

export default Images;
