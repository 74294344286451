import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { CheckIcon } from '../assets/icons';

const Container = styled.div`
  font-size: 16px;
  color: ${({ theme }) => theme.colors.white};
  input[type=checkbox]:before {
    content:"";
    display:inline-block; 
    width:16px;
    height:16px;
    background:${({ theme }) => theme.colors.gray60};
    border: 1px solid ${({ theme }) => theme.colors.secondary};
    border-radius: 2px;
  }
  input[type=checkbox]:checked:before {
    background:${({ theme }) => theme.colors.gray60};
    background-image: url(${CheckIcon});
    background-size: 24px 24px;
    background-position: center;
    background-repeat: no-repeat;
    padding: 0;
    border: 1px solid ${({ theme }) => theme.colors.secondary};
    border-radius: 2px;
  }
`;

const Input = styled.input`
  width: 16px;
  height: 16px;
`;

const CheckboxLine = ({
  className,
  checked,
  id,
  onChange,
  label,
}) => {
  const onChangeHolder = (evt) => {
    onChange(evt);
  };
  return (
    <Container className={className}>
      <Input
        type="checkbox"
        id={id}
        onChange={(e) => onChangeHolder(e)}
        checked={checked}
      />
      {' '}
      {label}
    </Container>
  );
};

CheckboxLine.propTypes = {
  id: PropTypes.string,
  onChange: PropTypes.func,
  checked: PropTypes.bool,
  className: PropTypes.string,
  label: PropTypes.string,
};

CheckboxLine.defaultProps = {
  onChange: () => {},
  label: '',
  checked: false,
  id: '',
  className: '',
};

export default CheckboxLine;
