/* eslint-disable react/no-array-index-key */
import React, { useContext, useState, useEffect } from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { colorBorderBox, subTitle } from '../../../styled/mixins';
import colors from '../../../styled/colors';
import { AlertsContext } from '../../../contexts/AlertsContext';
import { BYTES_LIMITS, BYTES_PER_MEGAS } from '../../../utils/constants/globals';
import { TrashIcon } from '../../../assets/icons';
import {
  SUCCESS_DELETE_MESSAGE,
  SUCCES_UPLOAD_IMAGES,
  ERROR_UPLOAD_IMAGES,
} from '../../../utils/constants/alerts';
import { Button } from '../../../components';
import { ImageProvider } from '../../../services/entities';

const Container = styled.div`
  margin: 16px;
  padding: 16px 24px;
  width: 750px;
  ${colorBorderBox}
  @media ${({ theme }) => (theme.device.tablet)} {
    width: 100%;
  }
`;

const SubTitle = styled.h3`
  ${subTitle}
`;

const ImagesContainer = styled.div`
`;

const ImageBox = styled.div`
  width: 100px;
  height: 100px;
  @media ${({ theme }) => (theme.device.tablet)} {
    width: 95px;
    height: 95px;
  }
`;

const Image = styled.img`
  width: 100%;
  height: 100%;
  object-fit: contain;
`;

const Row = styled.div`
  display: flex;
  width: 100%;
  padding: 8px;
  border: 1px solid ${({ theme }) => theme.colors.grayE9};
  @media ${({ theme }) => (theme.device.mobileL)} {
    justify-content: space-around;
  }
`;

const RowHeader = styled.div`
  width: 97px;
  font-weight: bold;
  font-size: 16px;
  display: flex;
  justify-content: center;
  align-items: center;
  @media ${({ theme }) => (theme.device.tablet)} {
    width: 95px;
  }
  ${({ theme, hiddenInMobile }) => (hiddenInMobile ? (
    `@media ${theme.device.mobileL} {
      display: none;
    }`
  ) : '')};
`;

const RowBody = styled.div`
  width: 100px;
  height: 100px;
  font-size: 16px;
  line-height: 1em;
  justify-content: center;
  align-items: center;
  display: flex;
  color: ${({ theme, isInvalid }) => (isInvalid ? theme.colors.white : theme.colors.text02)};
  ${({ theme, isInvalid }) => (isInvalid ? (
    `background-color: ${theme.colors.red};`
  ) : '')}
  @media ${({ theme }) => (theme.device.tablet)} {
    width: 95px;
    height: 95px;
  }
  ${({ theme, hiddenInMobile }) => (hiddenInMobile ? (
    `@media ${theme.device.mobileL} {
      display: none;
    }`
  ) : '')};
`;

const StatusText = styled.span`
  color: ${({ theme, isInvalid }) => (isInvalid ? theme.colors.red : theme.colors.green)};
  font-weight: bold;
`;

const Icon = styled.img`
  width: 20px;
  height: 20px;
  cursor: pointer;
`;

const BoldText = styled.a`
  color: ${({ theme }) => (theme.colors.brand5)};
  font-weight: 600;
  text-align:center;
  margin-top: 16px;
  cursor: pointer;
`;

const AccionBox = styled.div`
  width: 100px;
  height: 100px;
  justify-content: center;
  align-items: center;
  display: flex;
  flex-direction: column;
  @media ${({ theme }) => (theme.device.tablet)} {
    width: 95px;
    height: 95px;
  }
  ${({ theme, hiddenInMobile }) => (hiddenInMobile ? (
    `@media ${theme.device.mobileL} {
      display: none;
    }`
  ) : '')};
`;

const BtnContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
`;

const CustomButton = styled(Button)`
&&{
  width: 300px;
  @media ${({ theme }) => (theme.device.mobileL)} {
    width: 100%;
  }
}
`;

const Checker = ({
  images,
  setImages,
  setIsOpen,
  setSelectedUrl,
}) => {
  const { useToasts } = useContext(AlertsContext);
  const { addToast } = useToasts();
  const [isLoading, setIsLoading] = useState(false);
  const [disabled, setDisabled] = useState(true);
  const deleteImage = async (name) => {
    const newImages = [...images];
    setImages(newImages.filter((img) => img.name !== name));
    addToast(SUCCESS_DELETE_MESSAGE, { appearance: 'success', autoDismiss: true });
  };
  const onClick = (img) => {
    setSelectedUrl(img.src);
    setIsOpen(true);
  };
  const saveImages = async () => {
    setIsLoading(true);
    const imagesToSave = images.filter((img) => img.valid);
    const result = await ImageProvider.saveTemplates(imagesToSave);
    if (result.success) {
      setImages([]);
      addToast(SUCCES_UPLOAD_IMAGES, { appearance: 'success', autoDismiss: true });
    } else {
      addToast(ERROR_UPLOAD_IMAGES, { appearance: 'error', autoDismiss: true });
    }
    setIsLoading(false);
  };
  useEffect(() => {
    if (!images.length) {
      setDisabled(true);
    } else {
      const validImages = images.filter((img) => img.valid);
      setDisabled(!validImages.length);
    }
  }, [images]);
  return (
    <Container color={colors.brand5}>
      <SubTitle>
        Image checker
      </SubTitle>
      <ImagesContainer>
        {!!images.length && (
          <Row>
            <RowHeader>
              Image
            </RowHeader>
            <RowHeader hiddenInMobile>
              Size
            </RowHeader>
            <RowHeader hiddenInMobile>
              fonts
            </RowHeader>
            <RowHeader hiddenInMobile>
              Circles
            </RowHeader>
            <RowHeader hiddenInMobile>
              language
            </RowHeader>
            <RowHeader>
              Status
            </RowHeader>
          </Row>
        )}
        {images.map((img) => (
          <Row key={img.name}>
            <ImageBox>
              <Image src={img.src} />
            </ImageBox>
            <RowBody isInvalid={img.size >= BYTES_LIMITS} hiddenInMobile>
              {(img.size / BYTES_PER_MEGAS).toFixed(2)} <br /> MB
            </RowBody>
            <RowBody isInvalid={!img.hasValidFonts} hiddenInMobile>
              {img.hasValidFonts ? 'valid' : 'invalid' }
            </RowBody>
            <RowBody isInvalid={!!img.circles} hiddenInMobile>
              {img.circles || 0 }
            </RowBody>
            <RowBody isInvalid={!img.language} hiddenInMobile>
              {img.language || 'no language added'}
            </RowBody>
            <RowBody>
              <StatusText isInvalid={!img.valid}>
                {img.valid ? 'VALID' : 'INVALID'}
              </StatusText>
            </RowBody>
            <AccionBox hiddenInMobile>
              <Icon src={TrashIcon} onClick={() => deleteImage(img.name)} />
              <BoldText onClick={() => onClick(img)}>
                Editor
              </BoldText>
            </AccionBox>
          </Row>
        ))}
      </ImagesContainer>
      <BtnContainer>
        <CustomButton
          disabled={disabled}
          isLoading={isLoading}
          onClick={() => (saveImages())}
        >
          Upload valid images
        </CustomButton>
      </BtnContainer>
    </Container>
  );
};

Checker.propTypes = {
  images: PropTypes.instanceOf(Array).isRequired,
  setImages: PropTypes.func.isRequired,
  setIsOpen: PropTypes.func.isRequired,
  setSelectedUrl: PropTypes.func.isRequired,
};

export default Checker;
