import * as Sentry from '@sentry/react';
import { TagCategoriesMock } from '../../mocks/entities';
import {
  ENVIRONMENTS,
  ENV,
} from '../../utils/constants/globals';
import { getTagCategories } from '../../graphql/queries';

import appsyncClient from '../appsync/AppsyncClient';

export default class CategoryProvider {
  static async fetch() {
    let result;
    try {
      if (ENV !== ENVIRONMENTS.mock) {
        result = await appsyncClient.query({
          query: getTagCategories,
        });
      } else {
        result = TagCategoriesMock;
      }
      result = {
        message: 'success',
        success: true,
        data: result.data.getTagCategories,
      };
    } catch (err) {
      result = {
        message: err.message,
        success: false,
        data: [],
      };
      Sentry.captureException(err);
    }
    return result;
  }
}
