/* eslint-disable no-return-await */
import AWSAppSyncClient, { AUTH_TYPE } from 'aws-appsync';
import AppSyncConfig from './AppSyncConfig';
import Auth from '../Auth.provider';

const appsyncClient = new AWSAppSyncClient({
  url: AppSyncConfig.graphqlEndpoint,
  region: AppSyncConfig.region,
  auth: {
    type: AUTH_TYPE.AMAZON_COGNITO_USER_POOLS,
    apiKey: AppSyncConfig.apiKey,
    jwtToken: async () => await Auth.getTokenId(),
  },
  disableOffline: true,
});

export default appsyncClient;
