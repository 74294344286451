export const LANGUAGES_SUFIX = {
  en: '',
  es: 'Es',
};
export const ENV = process.env.REACT_APP_ENV;
export const ENVIRONMENTS = {
  development: 'development', staging: 'staging', production: 'production', mock: 'mock',
};
export const AMPLIFY_CONFY = {
  Auth: {
    region: process.env.REACT_APP_AWS_REGION,
    mandatorySignIn: true,
    userPoolId: process.env.REACT_APP_USER_POOL_ID,
    userPoolWebClientId: process.env.REACT_APP_COGNITO_APPCLIENT_ID,
  },
};
export const SENTRY_CONFY = {
  dsn: process.env.REACT_APP_SENTRY_DNS,
  environment: process.env.REACT_APP_ENV,
};
export const CONTENT_PATH = process.env.REACT_APP_S3_CONTENT_PATH;
export const IMAGE_LIMIT = 10;
export const BYTES_LIMITS = 3000000;
export const BYTES_PER_MEGAS = 1000000;
export const IMPORTS_FONTS = [
  "@import url('https://fonts.googleapis.com/css?family=Roboto');",
  "@import url('https://fonts.googleapis.com/css2?family=Open+Sans');",
  "@import url('https://fonts.googleapis.com/css2?family=Oswald');",
  "@import url('https://fonts.googleapis.com/css2?family=Montserrat');",
  "@import url('https://fonts.googleapis.com/css?family=PT+Sans');",
  "@import url('https://fonts.googleapis.com/css?family=Concert+One');",
  "@import url('https://fonts.googleapis.com/css?family=Cormorant');",
  "@import url('https://fonts.googleapis.com/css?family=Bungee+Shade');",
  "@import url('https://fonts.googleapis.com/css?family=Amatic+SC');",
  "@import url('https://fonts.googleapis.com/css?family=Wire+One');",
  "@import url('https://fonts.googleapis.com/css?family=Monoton');",
  "@import url('https://fonts.googleapis.com/css?family=Kaushan+Script');",
  "@import url('https://fonts.googleapis.com/css?family=Julius+Sans+One');",
  "@import url('https://fonts.googleapis.com/css2?family=Audiowide');",
  "@import url('https://fonts.googleapis.com/css2?family=Big+Shoulders+Inline+Text');",
  "@import url('https://fonts.googleapis.com/css2?family=Chakra+Petch');",
  "@import url('https://fonts.googleapis.com/css2?family=Courgette');",
  "@import url('https://fonts.googleapis.com/css2?family=Kurale');",
  "@import url('https://fonts.googleapis.com/css2?family=Lobster')",
  "@import url('https://fonts.googleapis.com/css2?family=Rubik+Mono+One')",
  "@import url('https://fonts.googleapis.com/css2?family=Sofia');",
];
export const FONT_FAMILIES = [
  'Roboto',
  'Open Sans',
  'Oswald',
  'Montserrat',
  'PT Sans',
  'Concert One',
  'Cormorant',
  'Bungee Shade',
  'Amatic SC',
  'Wire One',
  'Monoton',
  'Kaushan Script',
  'Julius Sans One',
  'TimesNewRomanPSMT',
  'ArialMT',
  'Audiowide',
  'Big Shoulders Inline Text',
  'Chakra Petch',
  'Courgette',
  'Kurale',
  'Lobster',
  'Rubik Mono One',
  'Sofia',
];
