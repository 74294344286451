import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import PIPER from '../assets/pipers';

const Container = styled.div`
  width: 100%;
  padding: 16px 0;
  display: flex;
  align-items: center;
`;

const Image = styled.img`
  width: 24px;
  height: 24px;
`;

const Advice = styled.div`
  color: ${({ theme }) => (theme.colors.primary)};
  font-size: 20px;
  font-style: normal;  
  font-weight: bold;
  margin-left: 16px;
`;

const PiperAdvice = ({ children }) => (
  <Container>
    <Image src={PIPER.headBackground} />
    <Advice>
      {children}
    </Advice>
  </Container>
);

PiperAdvice.propTypes = {
  children: PropTypes.node.isRequired,
};

export default PiperAdvice;
