import React from 'react';
import ReactDOM from 'react-dom';
import { ThemeProvider } from 'styled-components';
import { ApolloProvider } from 'react-apollo';
import Amplify from 'aws-amplify';
import * as Sentry from '@sentry/react';
import Rehydrated from './Rehydrated';
import './index.css';
import App from './App';
import * as serviceWorker from './serviceWorker';
import theme from './styled/theme';
import {
  AMPLIFY_CONFY, SENTRY_CONFY, ENV, ENVIRONMENTS,
} from './utils/constants/globals';
import appsyncClient from './services/appsync/AppsyncClient';
import AlertsProvider from './contexts/AlertsContext';

Amplify.configure(AMPLIFY_CONFY);

if (ENV === ENVIRONMENTS.production) {
  Sentry.init(SENTRY_CONFY);
}

ReactDOM.render(
  <React.StrictMode>
    <ApolloProvider client={appsyncClient}>
      <Rehydrated>
        <ThemeProvider theme={theme}>
          <AlertsProvider>
            <App />
          </AlertsProvider>
        </ThemeProvider>
      </Rehydrated>
    </ApolloProvider>
  </React.StrictMode>,
  document.getElementById('root'),
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
