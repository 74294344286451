import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import colors from '../styled/colors';

const Container = styled.div`
  position: relative;
`;

const TagsContainer = styled.div`
  display: none;
  position: relative;
`;

const TagsList = styled.div`
  background-color: ${({ theme }) => (theme.colors.gray90)};
  border-radius: 4px;
  color: ${({ theme }) => (theme.colors.white)};
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  font-size: 12px;
  bottom: 35px;
  right: -12px;
  padding: 7px;
  position: absolute;
  z-index: 1000;
  width: 200px;
`;

const TagContainer = styled.div`
  margin-right: 0.5ch;
`;

const ChipBox = styled.div`
  border-radius: 12px;
  background-color: ${({ color }) => (color)};
  color: ${({ theme }) => (theme.colors.white)};
  padding: 4px 8px;
  display: flex;
  align-items: center;
  font-size: 12px;
  margin: 4px;
  &:hover + div {
    display: block;
  }
  @media ${({ theme }) => (theme.device.tablet)} {
    &:hover + div {
        display: none;
      }
  }
`;

const Chip = ({
  className,
  label,
  inputs,
  color,
}) => (
  <Container>
    <ChipBox
      className={className}
      color={color}
    >
      {label}
    </ChipBox>
    <TagsContainer>
      <TagsList>
        {inputs.map((input, index) => (
          <TagContainer>
            {input.label}
            {inputs.length - 1 > index ? (',') : ('.')}
          </TagContainer>
        ))}
      </TagsList>
    </TagsContainer>
  </Container>
);

Chip.propTypes = {
  className: PropTypes.string,
  label: PropTypes.string.isRequired,
  inputs: PropTypes.instanceOf(Array).isRequired,
  color: PropTypes.string,
};

Chip.defaultProps = {
  className: '',
  color: colors.primary,
};

export default Chip;
