import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { LoaderIcon } from '../assets/icons';

const CustomButton = styled.button`
  color: ${(props) => (props.secondary ? props.theme.secondary : props.theme.primary)};
  border: 2px solid ${(props) => (props.secondary ? props.theme.secondary : props.theme.primary)};
  background: ${(props) => (props.secondary ? props.theme.primary : props.theme.secondary)};
  margin: 16px 0;
  padding: 12px 26px;
  border-radius: 4px;
  font-size: 16px;
  line-height: 16px;
  width: 100%;
  cursor: pointer;
  &:hover {
    background: ${(props) => (props.secondary ? 'rgb(0 0 0 / 5%)' : 'rgba(0, 96, 110, 1)')};
  }
  &:disabled {
    background: ${({ secondary, theme }) => (secondary ? 'rgb(0 0 0 / 5%)' : theme.colors.gray90)};
    cursor: auto;
  }
`;

const LoaderContainer = styled.div`
  display: flex;
  width: 100%;
  justify-content: center;
`;

const Loader = styled.img`
  width: 20px;
  height: 20px;
`;

const Button = ({
  children, onClick, secondary, disabled, className, isLoading,
}) => (
  <CustomButton
    onClick={onClick}
    secondary={secondary}
    disabled={disabled}
    className={className}
  >
    {!isLoading && children}
    {isLoading && (
      <LoaderContainer>
        <Loader src={LoaderIcon} />
      </LoaderContainer>
    )}
  </CustomButton>
);

Button.propTypes = {
  children: PropTypes.node.isRequired,
  onClick: PropTypes.func,
  secondary: PropTypes.bool,
  disabled: PropTypes.bool,
  className: PropTypes.string,
  isLoading: PropTypes.bool,
};

Button.defaultProps = {
  onClick: () => {},
  secondary: false,
  disabled: false,
  className: '',
  isLoading: false,
};

export default Button;
